import React from 'react'
import { CircularProgress } from '@chakra-ui/progress'
import { Text } from '@chakra-ui/react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { useLocalLocations } from 'hooks/localLocations'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

const InfoWindow: React.FC<{
  options: {
    map: google.maps.Map
    shouldFocus?: boolean
  }
  anchor: google.maps.Marker
  content: string | Element | null | Text
  onClose?: () => void
}> = ({ options, anchor, content, onClose }) => {
  const [infoWindow] = React.useState(new google.maps.InfoWindow())
  React.useEffect(() => {
    if (anchor?.getPosition()) {
      options?.map?.setZoom(MAX_ZOOM)
      options?.map?.panTo(anchor.getPosition()!)
      infoWindow.addListener('closeclick', () => {
        onClose?.()
      })
      infoWindow.setContent(content)
      infoWindow.setPosition(anchor.getPosition())
      options.shouldFocus && infoWindow.focus()
      infoWindow.open(options.map)
    }

    return () => {
      infoWindow.close()
    }
  })
  return null
}

function addMarker(
  location: google.maps.LatLngLiteral,
  label: string,
  map: google.maps.Map
) {
  return new google.maps.Marker({
    position: location,
    title: label,
    //label,
    map: map,
  })
}

const MAX_ZOOM = 12

const GoogleMap: React.FC<Props> = ({ onSelection }) => {
  const { data: localLocations } = useLocalLocations()
  const [locationsSet, setLocationsSet] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()
  const [activeMarkers, setActiveMarkers] = React.useState<
    google.maps.Marker[] | undefined
  >()

  React.useEffect(() => {
    if (ref.current && !map) {
      const center = { lat: 0, lng: 0 }
      const zoom = 2
      const map = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        maxZoom: MAX_ZOOM,
        minZoom: 0,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        fullscreenControl: false,
      })
      setMap(map)
    }
  }, [ref, map])

  React.useEffect(() => {
    if (localLocations !== undefined && !locationsSet && !!map) {
      setLocationsSet(true)
      const { id, ...locationsWithoutId } = localLocations
      const markers = Object.keys(locationsWithoutId).map((id) => {
        const localLocation = localLocations[id]
        const marker = addMarker(
          {
            lat: localLocation.lat,
            lng: localLocation.lng,
          },
          localLocation.userId,
          map
        )
        marker.addListener('click', () => {
          setActiveMarkers([marker])
          document?.getElementById('map-results')?.scrollIntoView({
            behavior: 'smooth',
          })
          onSelection([marker.getTitle()!])
        })
        return marker
      })

      const markerCluster = new MarkerClusterer({
        markers,
        onClusterClick: (e, cluster, map) => {
          if (cluster.markers === undefined || cluster.markers.length === 0) {
            return
          }

          map.fitBounds(cluster.bounds!)
          const zoom = map.getZoom() ?? 1
          if (zoom >= MAX_ZOOM) {
            setActiveMarkers(
              cluster.markers.map(
                (m) =>
                  new google.maps.Marker({
                    position: m.getPosition(),
                    title: m.getTitle()!,
                  })
              )
            )
            onSelection(cluster.markers.map((marker) => marker.getTitle()!))
          } else {
            onSelection(undefined)
            setActiveMarkers(undefined)
            map.fitBounds(cluster.bounds!)
          }
        },
      })

      markerCluster.setMap(map)
    }
  }, [localLocations, locationsSet, map, onSelection])

  return (
    <div
      ref={ref}
      id="map"
      style={{ width: '100%', height: '80vh', maxHeight: '80vh' }}
    >
      {activeMarkers && activeMarkers.length > 0 && (
        <InfoWindow
          content={`${
            activeMarkers.length === 1
              ? activeMarkers.length + ' local'
              : activeMarkers.length + ' locals'
          }  available, results below`}
          options={{
            map: map!,
            shouldFocus: true,
          }}
          anchor={activeMarkers[0]}
          onClose={() => {
            setActiveMarkers(undefined)
            onSelection(undefined)
          }}
        />
      )}
    </div>
  )
}

const MapLoading = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return <CircularProgress isIndeterminate />
    case Status.FAILURE:
      return <Text>Failed to load map, please try again.</Text>
    case Status.SUCCESS:
      return <></>
  }
}

type Props = {
  onSelection: (ids?: string[]) => void
}

const MapWrapper: React.FC<Props> = React.memo(({ onSelection }) =>
  process.env.REACT_APP_FRONTEND_GOOGLE_MAPS_API_KEY ? (
    <Wrapper
      apiKey={process.env.REACT_APP_FRONTEND_GOOGLE_MAPS_API_KEY}
      render={MapLoading}
    >
      <GoogleMap onSelection={onSelection} />
    </Wrapper>
  ) : (
    <p>No maps API key set</p>
  )
)

export default MapWrapper
