import { Heading, Spacer, Stack, Text } from '@chakra-ui/layout'
import {
  useRemoveStripePaymentMethodMutation,
  useStripeCustomer,
} from 'hooks/user'
import { IconButton } from '@chakra-ui/button'
import { CloseIcon } from '@chakra-ui/icons'
import SetupPayments from 'components/SetupPayments/SetupPayments'
import { CircularProgress } from '@chakra-ui/progress'

const Payments: React.FC = () => {
  const {
    data: stripeCustomer,
    isLoading: isLoadingStripeCustomer,
  } = useStripeCustomer()
  const removeStripePaymentMethodMutation = useRemoveStripePaymentMethodMutation()

  return (
    <>
      {isLoadingStripeCustomer && (
        <CircularProgress color="blue.500" isIndeterminate />
      )}
      {stripeCustomer && stripeCustomer.paymentsEnabled ? (
        <Stack direction="column" spacing={4}>
          <Heading size="sm">Connected Payments</Heading>
          {stripeCustomer.payments.map((payment) => (
            <Stack direction="row" spacing={2} key={payment.id} align="center">
              <Text fontWeight="bold">{payment.brand.toUpperCase()}</Text>
              <Text>
                **** **** **** {payment.lastFourDigits} expires{' '}
                {payment.expireMonth}/{payment.expireYear}
              </Text>
              <Spacer />
              <IconButton
                icon={<CloseIcon />}
                aria-label="Remove payment information"
                size="sm"
                variant="ghost"
                onClick={() =>
                  removeStripePaymentMethodMutation.mutate({ id: payment.id })
                }
              />
            </Stack>
          ))}
        </Stack>
      ) : (
        <SetupPayments />
      )}
    </>
  )
}
export default Payments
