import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import { Stack, Heading, Text, Center } from '@chakra-ui/layout'
import {
  useApproveWizardMutation,
  useUnseenWizards,
  useDenyWizardMutation,
} from 'hooks/admin'
import { useUserProfile } from 'hooks/user'
import { Box, Button, CircularProgress, Flex, Image } from '@chakra-ui/react'
import UserAvatar from 'components/UserAvatar/UserAvatar'
import LocationIcon from 'assets/icons/location.svg'

const Admin: React.FC = () => {
  const { data: profile } = useUserProfile()
  const {
    data: unseenWizards,
    isLoading: isLoadingUnseenWizards,
  } = useUnseenWizards()

  const approveWizardMutation = useApproveWizardMutation()
  const denyWizardMutation = useDenyWizardMutation()

  if (!profile?.isAdmin) {
    return (
      <DefaultLayout>
        <Center>
          <Heading>Permission Denied</Heading>
        </Center>
      </DefaultLayout>
    )
  }
  return (
    <DefaultLayout>
      <Stack
        direction="column"
        spacing={4}
        justify="center"
        align="center"
        py={4}
      >
        <Heading>Unseen Local Experts</Heading>
        {isLoadingUnseenWizards && <CircularProgress isIndeterminate />}
        {unseenWizards &&
          unseenWizards.map((wizard) => (
            <Box
              w="full"
              p={{ base: 4, sm: 8 }}
              key={wizard.id}
              borderRadius="4xl"
              border="2px solid"
              borderColor="lightGray"
            >
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                spacing={{ base: 2, sm: 0 }}
                w="full"
                align="center"
              >
                <UserAvatar userId={wizard.id} />
                <Stack
                  direction="column"
                  pl={{ base: 0, sm: 4 }}
                  spacing={{ base: 2, sm: 1 }}
                  w="3xs"
                  flexGrow={1}
                  align={{ base: 'center', sm: 'flex-start' }}
                >
                  <Heading size="sm" color="green">
                    {`${wizard.basicDetails?.firstName} ${wizard.basicDetails?.lastName}`}
                  </Heading>
                  <Flex direction="row" align="center">
                    <Image src={LocationIcon} w="24px" pr={1} />
                    <Heading size="xs">
                      {`${
                        wizard.basicDetails?.cityState ??
                        wizard.basicDetails?.city
                      }, ${wizard.basicDetails?.country}`}
                    </Heading>
                  </Flex>
                  <Text>
                    <b>Email: </b>
                    {wizard.email}
                  </Text>
                  <Text>
                    <b>About: </b>
                    {wizard.basicDetails?.about}
                  </Text>
                  <Text>
                    <b>Application letter: </b>
                    {wizard.wizardDetails?.internalApplicationLetter}
                  </Text>
                  <Text>
                    <b>Regions: </b>
                    {wizard.wizardDetails?.adviceRegionsList
                      ?.map((region) => region.label)
                      ?.join(' | ') ?? wizard.wizardDetails?.adviceRegions}
                  </Text>
                </Stack>
                <Stack
                  direction="column"
                  maxW={48}
                  spacing={4}
                  align="flex-end"
                >
                  <Flex direction="row" align="flex-end">
                    <Text textStyle="h4">€{wizard.wizardDetails?.rate}</Text>
                    <Text fontSize="xl" opacity=".5" ml={2}>
                      / hour
                    </Text>
                  </Flex>
                  <Button
                    w="full"
                    colorScheme="green"
                    variant="outline"
                    onClick={() =>
                      denyWizardMutation.mutate({ wizardId: wizard.id })
                    }
                    isLoading={denyWizardMutation.isLoading}
                    disabled={approveWizardMutation.isLoading}
                  >
                    Decline
                  </Button>
                  <Button
                    w="full"
                    colorScheme="green"
                    onClick={() =>
                      approveWizardMutation.mutate({ wizardId: wizard.id })
                    }
                    disabled={denyWizardMutation.isLoading}
                    isLoading={approveWizardMutation.isLoading}
                  >
                    Accept
                  </Button>
                </Stack>
              </Stack>
            </Box>
          ))}
      </Stack>
    </DefaultLayout>
  )
}
export default Admin
