import React from 'react'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TimezoneSelect, { ITimezone } from 'react-timezone-select'

import {
  BasicUserDetails,
  FormFieldsErrors,
  Timezone,
} from '@traviqo/data-model'
import { useUserProfile, useUserProfilePhoto } from 'hooks/user'
import ProfilePictureUpload from 'components/FileUpload/ProfilePictureUpload'
import countries from 'assets/countries.json'
import { useDebounce } from 'usehooks-ts'
import { useLocatioinsAutoComplete } from 'hooks/user'
import { Select as ReactSelect } from 'chakra-react-select'
import googleLogo from 'assets/google_on_white.png'
import { QuestionOutlineIcon } from '@chakra-ui/icons'

interface Props {
  initialValues?: BasicUserDetails
  errors?: FormFieldsErrors<BasicUserDetails>
  isLoading?: boolean
  onSubmit?: (values: BasicUserDetails, files: File[]) => void
  isSubmittingImages?: boolean
  onSubmitImages?: (files: File[]) => Promise<void>
}

const validationSchema = yup.object().shape({
  profilePic: yup
    .string()
    .required('A profile picture is required at Traviqo.'),
  firstName: yup
    .string()
    .required('Field is required')
    .max(255, 'Please enter a value under 256 characters'),
  lastName: yup
    .string()
    .required('Field is required')
    .max(255, 'Please enter a value under 256 characters'),
  country: yup
    .string()
    .required('Field is required')
    .max(255, 'Please enter a value under 256 characters'),
  cityState: yup.string().required('Field is required'),
  timezone: yup
    .object()
    .test(
      'isDefined',
      'Field is required',
      (value) => Object.keys(value).length > 0
    ),
  about: yup
    .string()
    .required('Field is required')
    .max(5000, 'Please enter a value under 5000 characters'),
})

const getCountryCode = (name: string) => {
  return countries.find((value) => value.name === name)?.code
}

const BasicUserInfoForm: React.FC<Props> = ({
  initialValues,
  errors,
  isLoading,
  onSubmit,
  //isSubmittingImages,
  //onSubmitImages,
}) => {
  //const [files, setFiles] = React.useState<File[]>([])
  const { data: profile } = useUserProfile()
  /*
  const { data: userImages, isLoading: isLoadingImages } = useUserImages(
    profile?.id
  )

  const fileURIs = React.useMemo(
    () => [
      ...(userImages?.map((image) => image.url) ?? []),
      ...files.map((file) => URL.createObjectURL(file)),
    ],
    [userImages, files]
  )
  const [imageGalleryIndex, setImageGalleryIndex] = React.useState<number>(0)
  */
  const { data: userProfilePhoto } = useUserProfilePhoto(profile?.id)

  const formikInitialValues = {
    profilePic: undefined as undefined | string,
    firstName: initialValues?.firstName ?? '',
    lastName: initialValues?.lastName ?? '',
    country: initialValues?.country ?? '',
    cityState: initialValues?.cityState ?? '',
    locationId: initialValues?.locationId ?? '',
    timezone: initialValues?.timezone ?? ({} as ITimezone),
    about: initialValues?.about ?? '',
  }
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: (values) => {
      const { profilePic, ...rest } = values
      onSubmit?.(
        {
          ...rest,
          timezone: values.timezone as Timezone,
        },
        []
        //files
      )
    },
  })

  React.useEffect(() => {
    if (userProfilePhoto && !formik.values.profilePic) {
      formik.resetForm({
        values: {
          ...formikInitialValues,
          ...formik.values,
          profilePic: userProfilePhoto,
        },
      })
    }
  })

  React.useEffect(() => {
    errors && formik.setErrors(errors)
  }, [errors, formik])

  const countryCode = React.useMemo(
    () => getCountryCode(formik.values.country),
    [formik.values.country]
  )

  const [cityStateInput, setCityStateInput] = React.useState('')
  const debouncedLocationInput = useDebounce(cityStateInput, 500)
  const {
    data: locationsAutoComplete,
    isLoading: isLoadingAutoComplete,
  } = useLocatioinsAutoComplete(debouncedLocationInput, countryCode, 'city')

  React.useEffect(() => {
    errors && formik.setErrors(errors)
  }, [errors, formik])

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack direction="column" spacing="4">
        {/*
        <FileUpload
          value={files}
          onChange={(files) => {
            setFiles(files)
            userImages && setImageGalleryIndex(userImages.length)
          }}
        />
        {fileURIs.length > 0 && (
          <ImageGallery
            images={fileURIs}
            activeIndex={imageGalleryIndex}
            onActiveIndexChange={(newIndex) => setImageGalleryIndex(newIndex)}
          />
        )}
        {isLoadingImages && <CircularProgress color='blue.500' isIndeterminate />}
        */}
        {/*
        {onSubmitImages && files.length > 0 && (
          <Button
            size="lg"
            isLoading={isSubmittingImages}
            onClick={async () => {
              await onSubmitImages(files)
              setFiles([])
            }}
            w="fit-content"
          >
            Save Images
          </Button>
        )}
          */}
        <FormControl isRequired isInvalid={userProfilePhoto === undefined}>
          <FormLabel>
            Set your profile picture
            <Tooltip label="We love to see you smile! Please set a profile picture, so users know who you are.">
              <QuestionOutlineIcon
                boxSize={4}
                verticalAlign="top"
                mt={0.5}
                ml={0.5}
              />
            </Tooltip>
          </FormLabel>
          <ProfilePictureUpload userId={profile?.id} />
          <FormErrorMessage>{formik.errors.profilePic}</FormErrorMessage>
        </FormControl>
        <Stack direction={{ base: 'column', sm: 'row' }} spacing="4">
          <FormControl
            isRequired
            isInvalid={!!formik.errors.firstName && formik.touched.firstName}
          >
            <FormLabel>First Name</FormLabel>
            <Input
              name="firstName"
              autoComplete="given-name"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!formik.errors.lastName && formik.touched.lastName}
          >
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              autoComplete="family-name"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
          </FormControl>
        </Stack>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.country && formik.touched.country}
        >
          <FormLabel>Country</FormLabel>
          <Select
            value={formik.values.country}
            onChange={(e) => {
              formik.setFieldValue('country', e.target.value)
              formik.setFieldValue('cityState', '')
            }}
            placeholder="Select..."
          >
            {countries.map((country) => (
              <option key={country.name} value={country.name}>
                {country.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{formik.errors.country}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.cityState && formik.touched.cityState}
        >
          <FormLabel>City, State/province</FormLabel>
          <ReactSelect
            isLoading={isLoadingAutoComplete}
            options={locationsAutoComplete?.map((option) => ({
              value: option.locationId,
              label: option.label.split(',').slice(0, -1).join(','),
            }))}
            value={{
              label: formik.values.cityState,
              value: formik.values.locationId,
            }}
            menuIsOpen={
              cityStateInput.length > 2 &&
              !isLoadingAutoComplete &&
              cityStateInput === debouncedLocationInput
            }
            isMulti={false}
            inputValue={cityStateInput}
            onInputChange={(value) => setCityStateInput(value)}
            onChange={(newValue) => {
              formik.setFieldValue('cityState', newValue?.label ?? '')
              formik.setFieldValue('locationId', newValue?.value ?? '')
            }}
            useBasicStyles
            chakraStyles={{
              dropdownIndicator: () => ({
                display: 'none',
              }),
              menuList: (provided) => ({
                ...provided,
                _after: {
                  height: '18px',
                  content: '""',
                  display: 'block',
                  background: `transparent url(${googleLogo}) no-repeat center`,
                },
              }),
            }}
          />
          <FormErrorMessage>{formik.errors.cityState}</FormErrorMessage>
        </FormControl>
        {/*
        <Stack direction={{ base: 'column', sm: 'row' }} spacing="4">
          <FormControl
            isRequired
            isInvalid={!!formik.errors.city && formik.touched.city}
          >
            <FormLabel>City</FormLabel>
            <Input
              name="city"
              autoComplete="address-level2"
              onChange={formik.handleChange}
              value={formik.values.city}
              disabled={formik.values.country === ''}
            />
            <FormErrorMessage>{formik.errors.city}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!formik.errors.state && formik.touched.state}
          >
            <FormLabel>State/province</FormLabel>
            <Input
              name="state"
              autoComplete="address-level1"
              onChange={formik.handleChange}
              value={formik.values.state}
              disabled={formik.values.country === ''}
            />
            <FormErrorMessage>{formik.errors.state}</FormErrorMessage>
          </FormControl>
        </Stack>
        */}
        <FormControl
          isRequired
          isInvalid={!!formik.errors.timezone && formik.touched.timezone}
        >
          <FormLabel>What time zone are you currently in?</FormLabel>
          <TimezoneSelect
            value={formik.values.timezone}
            onChange={(timezone) => formik.setFieldValue('timezone', timezone)}
          />
          <FormErrorMessage>{formik.errors.timezone}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.about && formik.touched.about}
        >
          <FormLabel>
            Your bio. Here you can tell a bit more about yourself – from your
            hobbies to travel interests.
            <Tooltip label="If you join us as a local expert, it’s good to take your time for this part. Our travelers really like to get an idea about you as a person, so try to use at least 100 words to tell us a bit about you.">
              <QuestionOutlineIcon
                boxSize={4}
                verticalAlign="top"
                mt={0.5}
                ml={0.5}
              />
            </Tooltip>
          </FormLabel>
          <Textarea
            name="about"
            onChange={formik.handleChange}
            value={formik.values.about}
          />
          <FormErrorMessage>{formik.errors.about}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          size={initialValues === undefined ? 'lg' : 'md'}
          isLoading={isLoading}
          isDisabled={initialValues !== undefined && !formik.dirty}
        >
          {initialValues === undefined ? 'Create account' : 'Save'}
        </Button>
      </Stack>
    </form>
  )
}
export default BasicUserInfoForm
