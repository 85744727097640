import React, { useEffect, useState } from 'react'
import {
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { verifyPasswordReset } from 'services/firebaseService'
import { useUpdatePasswordMutation } from 'hooks/user'
import logo64 from 'assets/icons/logo64.png'
import { ROUTES } from 'ts/siteConstants'

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Please enter a valid password')
    .min(8, 'Please choose a longer password'),
  passwordConfirmation: yup
    .string()
    .required('Please enter a valid password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
})

const PasswordUpdate: React.FC = () => {
  const location = useLocation()
  const [passwordUpdated, setPasswordUpdated] = useState(false)
  const [validCode, setValidCode] = useState(true)
  const query = new URLSearchParams(location.search)
  const code = query.get('code')
  const updatePasswordMutation = useUpdatePasswordMutation()

  const verifyCode = async (code: string) =>
    setValidCode(await verifyPasswordReset(code))

  useEffect(() => {
    code && verifyCode(code)
  }, [code])

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      await updatePasswordMutation.mutateAsync({
        code: code as string,
        password: values.password,
      })
      actions.setSubmitting(false)
      setPasswordUpdated(true)
    },
  })

  return (
    <Center minH="100vh">
      <Stack direction="column" maxW="sm" w="full" m={4} p={4} spacing={4}>
        <Image
          src={logo64}
          alt="Traviqo logo"
          w="fit-content"
          alignSelf="center"
        />
        <Heading size="md" textAlign="center">
          Set New Password
        </Heading>
        {passwordUpdated ? (
          <>
            <Text textAlign="center">Password has been reset.</Text>
            <Button
              as={RouterLink}
              to={ROUTES.login}
              state={location.state}
              w="full"
            >
              To Login
            </Button>
          </>
        ) : !code || !validCode ? (
          <>
            <Text textAlign="center">
              The code is expired or invalid, please retry resetting your
              password.
            </Text>
            <Button
              as={RouterLink}
              to={ROUTES.login}
              state={location.state}
              w="full"
            >
              To Login
            </Button>
          </>
        ) : (
          <Stack
            direction="column"
            spacing={4}
            as="form"
            onSubmit={formik.handleSubmit as any}
            noValidate
          >
            <FormControl
              isRequired
              isInvalid={
                formik.touched.password && Boolean(formik.errors.password)
              }
            >
              <FormLabel>Password</FormLabel>
              <Input
                id="password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
              />
              <FormErrorMessage>
                {formik.errors.passwordConfirmation}
              </FormErrorMessage>
            </FormControl>
            <Button type="submit" isLoading={formik.isSubmitting}>
              Reset Password
            </Button>
          </Stack>
        )}
      </Stack>
    </Center>

    /*
    <Center h="100vh" pos="relative">
      <Container maxW="md" w="full">
        <Heading size="md" mb="30px" mt="50px">
          Passwort zurücksetzen
        </Heading>
        {passwordUpdated ? (
          <Stack spacing={5}>
            <Text textStyle="p">Passwort wurde aktualisiert.</Text>
            <Button as={RouterLink} to={NAVIGATION_LINKS.login}>
              Zum Login
            </Button>
          </Stack>
        ) : !code || !validCode ? (
          <>
            <Text textStyle="p">
              Der Reset-Code kann nicht überprüft werden. Bitte versuchen Sie es
              erneut.
            </Text>
            <Button
              as={RouterLink}
              to={NAVIGATION_LINKS.login}
              variant="outline"
              w="full"
              mt={5}
            >
              Zum Login
            </Button>
          </>
        ) : (
          <Stack
            direction="column"
            spacing={5}
            as="form"
            onSubmit={formik.handleSubmit as any}
            noValidate
          >
            <FormControl
              isRequired
              isInvalid={
                formik.touched.password && Boolean(formik.errors.password)
              }
            >
              <FormLabel>
                Passwort
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormLabel>
              <Input
                id="password"
                name="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </FormControl>
            <FormControl
              isRequired
              isInvalid={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
            >
              <FormLabel>
                Passwort wiederholen
                <FormErrorMessage>
                  {formik.errors.passwordConfirmation}
                </FormErrorMessage>
              </FormLabel>
              <Input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
              />
            </FormControl>
            <Button type="submit" isLoading={formik.isSubmitting}>
              Passwort erneuern
            </Button>
          </Stack>
        )}
      </Container>
    </Center>
    */
  )
}
export default PasswordUpdate
