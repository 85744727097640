import React from 'react'
import { Button, Center, Heading, Stack, Tooltip } from '@chakra-ui/react'
import RoleSelect, { roleOptions } from 'components/RoleSelect/RoleSelect'
import { useUserProfile, useUserProfileMutation } from 'hooks/user'
import { User, UserRole } from '@traviqo/data-model'
import { useNavigate } from 'react-router'
import { ROUTES } from 'ts/siteConstants'
import { QuestionOutlineIcon } from '@chakra-ui/icons'

const InitialUserRole: React.FC = () => {
  const navigate = useNavigate()
  const { data: profile } = useUserProfile()
  const userProfileMutation = useUserProfileMutation()
  const [role, setRole] = React.useState<UserRole>(roleOptions[0].value)
  return (
    <Center minH="100vh" overflowY="scroll" m="4">
      <Stack spacing="4" direction="column" maxW="lg" w="full">
        <Heading size="md" alignSelf="center">
          Why are you on Traviqo?
          <Tooltip label="No worries, you can always change your role later!">
            <QuestionOutlineIcon
              boxSize={5}
              verticalAlign="top"
              mt="10px"
              ml="10px"
            />
          </Tooltip>
        </Heading>
        <RoleSelect value={role} onChange={setRole} />
        <Button
          onClick={() =>
            userProfileMutation.mutate(
              { user: { ...(profile as User), role } },

              {
                onSuccess: (_, data) => {
                  navigate(ROUTES.home)
                },
              }
            )
          }
        >
          Continue
        </Button>
      </Stack>
    </Center>
  )
}
export default InitialUserRole
