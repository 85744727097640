import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { NewBooking, WizardPublicProfile } from '@traviqo/data-model'
import BookWizardForm from 'forms/BookWizardForm/BookWizardForm'

type Props = {
  wizard: WizardPublicProfile
  onSubmit?: (
    newBooking: Omit<NewBooking, 'wizardId' | 'user' | 'wizard' | 'fee'>
  ) => void
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const BookWizardModal: React.FC<Props> = ({
  wizard,
  onSubmit,
  isOpen: isOpenProp,
  onOpen: onOpenProp,
  onClose: onCloseProp,
}) => {
  const { isOpen, onClose } = useDisclosure({
    isOpen: isOpenProp,
    onOpen: onOpenProp,
    onClose: onCloseProp,
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Let {wizard.firstName} plan your trip</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={4}>
          <BookWizardForm wizard={wizard} onSubmit={onSubmit} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default BookWizardModal
