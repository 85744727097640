import { DeepPartial } from '@chakra-ui/react'
import {
  FormFieldsErrors,
  LocationAutoCompleteType,
  Reviews,
  User,
  WizardSearch,
} from '@traviqo/data-model'
import {
  AuthError,
  AuthErrorCodes,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { LoginFields } from 'forms/LoginForm/LoginForm'
import { RegisterFields } from 'forms/RegisterForm/RegisterForm'
import { useMutation, useQuery } from 'react-query'
import {
  getUserPictures,
  getUserProfile,
  getWizards,
  getWizard,
  setUserProfile,
  uploadUserPhotos,
  getStripeOnboardWizardLink,
  StripeOnboardLink,
  getStripeCustomer,
  getStripeCustomerSetupIntent,
  removeStripePaymentMethod,
  uploadUserProfilePhoto,
  getUserProfilePhoto,
  getLocatioinsAutoComplete,
  sendPasswordResetEmail,
  updatePassword,
  updateUserProfile,
  deactivateWizardProfile,
  getUserReviews,
} from 'services/firebaseService'
import { queryClient } from 'ts/queryClient'
import { getStripeAccount } from '../services/firebaseService'

export const useUser = () => useQuery('user', () => getAuth().currentUser)

export const useRegisterMutation = () =>
  useMutation<
    void,
    Error & { fields: FormFieldsErrors<RegisterFields> },
    { email: string; password: string }
  >(async ({ email, password }) => {
    const auth = getAuth()
    try {
      await createUserWithEmailAndPassword(auth, email, password)
    } catch (e) {
      const error: AuthError = e as any
      let fields: FormFieldsErrors<RegisterFields>
      if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
        fields = {
          email: 'A user with that email already exists',
          password: '',
        }
      } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
        fields = {
          email: 'Invalid Email address',
          password: '',
        }
      } else if (error.code === AuthErrorCodes.OPERATION_NOT_ALLOWED) {
        fields = {
          email:
            'Registration is not allowed at this time, please check back later',
          password: '',
        }
      } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
        fields = {
          email: '',
          password: 'Please use a more secure password',
        }
      } else {
        fields = {
          email: 'An error occurred, please try again',
          password: '',
        }
      }
      throw Object.assign(new Error(error.code), { fields })
    }
  })

export const useLoginMutation = () =>
  useMutation<
    void,
    Error & { fields: FormFieldsErrors<LoginFields> },
    { email: string; password: string }
  >(async ({ email, password }) => {
    const auth = getAuth()
    try {
      await signInWithEmailAndPassword(auth, email, password)
    } catch (e) {
      const error: AuthError = e as any
      let fields: FormFieldsErrors<LoginFields>
      if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
        fields = {
          email: '',
          password: 'Incorrect password',
        }
      } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
        fields = {
          email: 'Invalid Email address',
          password: '',
        }
      } else if (error.code === AuthErrorCodes.USER_DISABLED) {
        fields = {
          email: 'User has been disabled',
          password: '',
        }
      } else if (error.code === AuthErrorCodes.USER_DELETED) {
        fields = {
          email: 'User with email does not exist',
          password: '',
        }
      } else {
        fields = {
          email: 'An error occurred, please try again',
          password: '',
        }
      }
      throw Object.assign(new Error(error.code), { fields })
    }
  })

export const useLogoutMutation = () =>
  useMutation(async () => {
    const auth = getAuth()
    signOut(auth)
  })

const USER_PROFILE_QUERY_KEY = 'profile'

export const useUserProfile = () => {
  const { data: user } = useUser()
  return useQuery(
    USER_PROFILE_QUERY_KEY,
    async () => await getUserProfile(user!.uid),
    {
      enabled: !!user,
    }
  )
}

export const useUserImages = (uid?: string) =>
  useQuery(['images', uid], async () => getUserPictures(uid as string), {
    enabled: !!uid,
  })

export const useUserProfilePhoto = (uid?: string) =>
  useQuery(
    ['profilePhoto', uid],
    async () => getUserProfilePhoto(uid as string),
    {
      enabled: !!uid,
    }
  )

export const useSetUserProfilePhotoMutation = () =>
  useMutation<void, Error, { userId: string; file: File }>(
    async ({ userId, file }) => {
      await uploadUserProfilePhoto(userId, file)
    },
    {
      onSuccess: async (data, { userId, file }) => {
        queryClient.invalidateQueries(['profilePhoto', userId])
      },
    }
  )

export const usePasswordResetMutation = () =>
  useMutation<void, Error, { email: string }>(async ({ email }) => {
    await sendPasswordResetEmail(email)
  })

export const useUpdatePasswordMutation = () =>
  useMutation<void, Error, { code: string; password: string }>(
    async ({ code, password }) => {
      await updatePassword(code, password)
    }
  )

export const useUserImageMutation = () =>
  useMutation<void, Error, { uid: string; files: File[] }>(
    async ({ uid, files }) => {
      await uploadUserPhotos(uid, files)
    },
    {
      onSuccess: async (data, { uid, files }) => {
        const queryKey = ['images', uid]
        queryClient.setQueryData(queryKey, [
          ...(queryClient.getQueryData<
            {
              path: string
              url: string
            }[]
          >(queryKey) ?? []),
          ...files.map((file) => ({
            path: URL.createObjectURL(file),
            url: URL.createObjectURL(file),
          })),
        ])
      },
    }
  )

export const useCreateUserProfileMutation = () =>
  useMutation<void, Error, { user: User; files?: File[] }>(
    async ({ user, files }) => {
      files && (await uploadUserPhotos(user.id, files))
      await setUserProfile(user)
    },
    {
      onSuccess: () => queryClient.invalidateQueries('profile'),
    }
  )

export const useUserProfileMutation = () =>
  useMutation<
    void,
    Error,
    { user: DeepPartial<User> & { id: string }; files?: File[] }
  >(
    async ({ user, files }) => {
      files && (await uploadUserPhotos(user.id, files))
      await updateUserProfile(user)
    },
    {
      onSuccess: () => queryClient.invalidateQueries(USER_PROFILE_QUERY_KEY),
      onError: () => {
        queryClient.invalidateQueries(USER_PROFILE_QUERY_KEY)
      },
    }
  )

export const useDeactivateWizardProfileMutation = () =>
  useMutation<void, Error, { userId: string }>(
    async ({ userId }) => {
      await deactivateWizardProfile(userId)
    },
    {
      onSuccess: () => queryClient.invalidateQueries('profile'),
    }
  )

export const useWizards = (search: WizardSearch) =>
  useQuery(
    ['wizards', search.wizardIds?.join(','), search.location],
    async () => await getWizards(search),
    { enabled: search.location !== undefined || search.wizardIds !== undefined }
  )

export const useWizard = (id?: string) =>
  useQuery(['wizard', id], async () => await getWizard(id ?? ''), {
    enabled: !!id,
    retry: false,
  })

export const useLocatioinsAutoComplete = (
  prefix: string,
  countryCode?: string,
  type?: LocationAutoCompleteType
) =>
  useQuery(
    ['locationAutoComplete', countryCode, prefix],
    async () => await getLocatioinsAutoComplete(prefix, countryCode, type),
    {
      enabled: prefix.length > 2,
    }
  )

export const useStripeCustomer = () =>
  useQuery('stripeCustomer', async () => await getStripeCustomer(), {
    staleTime: 0,
  })

export const useRemoveStripePaymentMethodMutation = () =>
  useMutation<string, Error, { id: string }>(
    async ({ id }) => await removeStripePaymentMethod(id),
    {
      onSuccess: () => queryClient.invalidateQueries('stripeCustomer'),
    }
  )

export const useStripeCustomerSetupIntent = () =>
  useQuery(
    'stripeCustomerSetupIntent',
    async () => await getStripeCustomerSetupIntent(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: () => queryClient.invalidateQueries('profile'),
    }
  )

export const useStripeAccount = (enabled?: boolean) =>
  useQuery('stripeAccount', async () => await getStripeAccount(), {
    enabled: enabled ?? true,
    onSuccess: () => queryClient.invalidateQueries('profile'),
  })

export const useStripeOnboard = (enabled?: boolean) =>
  useQuery<Partial<StripeOnboardLink>>(
    'stripeOnboarding',
    async () => await getStripeOnboardWizardLink(),
    {
      enabled: enabled ?? true,
      onSuccess: () => queryClient.invalidateQueries('profile'),
    }
  )

export const useWizardReviews = (wizardId: string) =>
  useQuery<Reviews>(
    ['wizardReviews', wizardId],
    async () => await getUserReviews(wizardId)
  )
