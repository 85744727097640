import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import { ROUTES } from 'ts/siteConstants'

import { FormFieldsErrors } from '@traviqo/data-model'

export interface LoginFields {
  email: string
  password: string
}

interface Props {
  initialValues?: LoginFields
  errors?: FormFieldsErrors<LoginFields>
  onSubmit?: (values: LoginFields) => void
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter an email')
    .email('Please enter a valid email'),
  password: yup.string().required('Please enter your password'),
})

const LoginForm: React.FC<Props> = ({ initialValues, errors, onSubmit }) => {
  const location = useLocation()
  const formik = useFormik({
    initialValues: {
      email: initialValues?.email ?? '',
      password: initialValues?.password ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit && onSubmit(values)
    },
  })

  useEffect(() => {
    errors && formik.setErrors(errors)
  }, [errors, formik])

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack spacing="4">
        <FormControl
          isRequired
          isInvalid={!!formik.errors.email && formik.touched.email}
        >
          <FormLabel>Email address</FormLabel>
          <Input
            name="email"
            type="email"
            autoComplete="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.password && formik.touched.password}
        >
          <FormLabel>Password</FormLabel>
          <Input
            name="password"
            type="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
        </FormControl>
        <Link
          as={RouterLink}
          to={{ pathname: ROUTES.passwordReset }}
          state={location.state}
          size="sm"
          textAlign="center"
          variant="ghost"
        >
          Forgot Password
        </Link>
        <Button type="submit" size="lg">
          Sign in
        </Button>
      </Stack>
    </form>
  )
}
export default LoginForm
