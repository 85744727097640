import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react'
import { UserReview } from '@traviqo/data-model'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  howDidItGo: yup.string().required('Required'),
  chargeCustomer: yup.boolean(),
})

type Props = {
  bookingId: string
  onSubmit?: (data: UserReview) => Promise<void>
}

// wizard review of a user
const UserReviewForm: React.FC<Props> = ({ bookingId, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      howDidItGo: '',
      chargeCustomer: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      await onSubmit?.({ ...values, id: bookingId })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack direction="column" spacing="4">
        <Heading fontStyle="italic" size="xs">
          Your review is for internal use only and won't be shared publicly
        </Heading>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.howDidItGo && formik.touched.howDidItGo}
        >
          <FormLabel>How did the meeting go?</FormLabel>
          <Input
            name="howDidItGo"
            onChange={formik.handleChange}
            value={formik.values.howDidItGo}
          />
          <FormErrorMessage>{formik.errors.howDidItGo}</FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.chargeCustomer && formik.touched.chargeCustomer
          }
        >
          <Checkbox
            name="chargeCustomer"
            onChange={formik.handleChange}
            checked={formik.values.chargeCustomer}
          >
            The meeting has been completed and the customer should be charged
          </Checkbox>
          <FormErrorMessage>{formik.errors.chargeCustomer}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          size="lg"
          isLoading={formik.isSubmitting}
          isDisabled={!formik.dirty}
        >
          Submit
        </Button>
      </Stack>
    </form>
  )
}
export default UserReviewForm
