import React from 'react'
import {
  Button,
  Center,
  CircularProgress,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import BookWizardModal from 'components/BookWizardModal/BookWizardModal'
import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import NotFound from 'components/NotFound/NotFound'
import { useCreateBookingMutation } from 'hooks/booking'
import { useUser, useWizard } from 'hooks/user'
import {
  generatePath,
  useNavigate,
  useMatch,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import { ROUTES } from 'ts/siteConstants'
import UserAvatar from 'components/UserAvatar/UserAvatar'
import { BOOKING_ADDONS, BOOKING_ADDON_DISPLAY } from '@traviqo/data-model'
import ReviewsCard from 'components/ReviewsCard/ReviewsCard'

const WizardDetail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: user } = useUser()
  let location = useLocation()
  const navigate = useNavigate()
  const createBookingMutation = useCreateBookingMutation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast({
    position: 'bottom',
    status: 'success',
    duration: 4000,
    isClosable: true,
  })
  const match = useMatch(ROUTES.wizardDetail)
  const wizardId = match?.params.wizardId
  const {
    data: wizard,
    isLoading: isLoadingWizard,
    error: wizardLoadError,
  } = useWizard(wizardId)

  React.useEffect(() => {
    if (
      searchParams.get('open') !== undefined &&
      searchParams.get('open') !== null
    ) {
      setSearchParams({}, { replace: true })
      onOpen()
    }
    // eslint-disable-next-line
  }, [searchParams.get('open'), onOpen, setSearchParams])

  if (isLoadingWizard) {
    return (
      <DefaultLayout>
        <Center>
          <CircularProgress color="blue.500" isIndeterminate />
        </Center>
      </DefaultLayout>
    )
  }
  if (!match || !wizardId || wizardLoadError || wizard === undefined) {
    return (
      <NotFound
        message="Local Expert not found"
        returnToRoute={ROUTES.travelerDashboard}
        returnToMessage="Return to Local Expert list"
      />
    )
  }
  return (
    <DefaultLayout>
      <Heading textAlign="center" mb={4} size="md">
        About {wizard.firstName}
      </Heading>
      <Stack direction="column" spacing={4}>
        <Stack
          w="full"
          p={{ base: 4, sm: 8 }}
          borderRadius="4xl"
          border="2px solid"
          borderColor="lightGray"
          direction="column"
          spacing={4}
        >
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: 2, sm: 0 }}
            w="full"
            align="center"
          >
            <UserAvatar userId={wizardId} />
            <Stack
              direction="column"
              pl={{ base: 0, sm: 4 }}
              spacing={{ base: 2, sm: 1 }}
              w="3xs"
              flexGrow={1}
              align={{ base: 'center', sm: 'flex-start' }}
            >
              <Heading size="sm" color="green">
                {`${wizard.firstName} ${wizard.lastName}`}
              </Heading>
              {/*
              <Flex direction="row" align="center">
                <Image src={LocationIcon} w="24px" pr={1} />
                <Heading size="xs">{`${wizard.cityState ?? wizard.city}, ${
                  wizard.country
                }`}</Heading>
              </Flex>
              */}
            </Stack>
            <Stack
              direction="column"
              align={{ base: 'center', sm: 'flex-end' }}
            >
              <Flex direction="row" align={{ base: 'center', sm: 'flex-end' }}>
                <Text textStyle="h4">€{wizard.rate}</Text>
                <Text fontSize="xl" opacity=".5" ml={2}>
                  / hour
                </Text>
              </Flex>
              <Button
                colorScheme="green"
                onClick={() =>
                  user !== null
                    ? onOpen()
                    : navigate(ROUTES.login, {
                        state: { from: location.pathname + '?open=true' },
                      })
                }
              >
                Get in touch
              </Button>
            </Stack>
          </Stack>
          <Text>
            <b>About: </b>
            {wizard.about}
          </Text>
          <Text>
            <b>Knows about: </b>
            {wizard.adviceRegionsList
              ?.map((region) => region.label)
              .join(' | ') ?? wizard.adviceRegions}
          </Text>
          {wizard.whereExpert && (
            <Text>
              <b>Why {wizard.firstName} is a local expert: </b>
              {wizard.whereExpert ?? ''}
            </Text>
          )}
          <Text>
            <b>Can help you with: </b>
            {(wizard.offeredAddons ?? BOOKING_ADDONS)
              .map((addon) => BOOKING_ADDON_DISPLAY[addon].name)
              .join(', ')}
          </Text>
        </Stack>
        <ReviewsCard wizardId={wizardId} />
        <Button
          colorScheme="green"
          onClick={() =>
            user !== null
              ? onOpen()
              : navigate(ROUTES.login, {
                  state: { from: location.pathname + '?open=true' },
                })
          }
        >
          Get in touch
        </Button>
      </Stack>
      <BookWizardModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        wizard={wizard}
        onSubmit={async (newBooking) => {
          const newBookingId = await createBookingMutation.mutateAsync({
            ...newBooking,
            wizardId: wizardId!,
          })
          toast({ title: 'Request sent' })
          onClose()
          navigate(
            generatePath(ROUTES.bookingDetail, { bookingId: newBookingId })
          )
        }}
      />
    </DefaultLayout>
  )
}
export default WizardDetail
