import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  ChakraProvider,
  CSSReset,
  GlobalStyle,
  PortalManager,
  extendTheme,
} from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'

import { queryClient } from 'ts/queryClient'
import theme from 'ts/theme'

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={extendTheme(theme)}>
        <CSSReset />
        <GlobalStyle />
        <PortalManager zIndex={40}>
          <Router>
            <App />
          </Router>
        </PortalManager>
      </ChakraProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
