import {
  AcceptBooking,
  CancelBooking,
  DeclineBooking,
  NewBooking,
  UserReview,
  WizardReview,
} from '@traviqo/data-model'
import { useMutation, useQuery } from 'react-query'
import {
  createBooking,
  getBookings,
  getBooking,
  getWizardBookings,
  acceptBooking,
  declineBooking,
  cancelBooking,
  saveUserReview,
  saveWizardReview,
} from 'services/firebaseService'
import { queryClient } from 'ts/queryClient'

export const useCreateBookingMutation = () =>
  useMutation<string, Error, NewBooking>(
    async (newBooking) => await createBooking(newBooking),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('bookings')
      },
    }
  )

export const useAcceptBookingMutation = () =>
  useMutation<void, Error, AcceptBooking>(
    async (acceptBookingArg) => await acceptBooking(acceptBookingArg),
    {
      onSuccess: (_, acceptBookingArg) => {
        queryClient.invalidateQueries(['booking', acceptBookingArg.id])
      },
    }
  )

export const useDeclineBookingMutation = () =>
  useMutation<void, Error, DeclineBooking>(
    async (declineBookingArg) => await declineBooking(declineBookingArg),
    {
      onSuccess: (_, declineBookingArg) => {
        queryClient.invalidateQueries(['booking', declineBookingArg.id])
      },
    }
  )

export const useCancelBookingMutation = () =>
  useMutation<void, Error, CancelBooking>(
    async (cancelBookingArg) => await cancelBooking(cancelBookingArg),
    {
      onSuccess: (_, cancelBookingArg) => {
        queryClient.invalidateQueries(['booking', cancelBookingArg.id])
      },
    }
  )

export const useBookings = () => useQuery('bookings', () => getBookings())

export const useBooking = (id?: string) =>
  useQuery(['booking', id], () => getBooking(id ?? ''), {
    enabled: !!id,
    retry: false,
  })

export const useWizardBookings = (hasWizardProfile: boolean) =>
  useQuery('wizard-bookings', () => getWizardBookings(), {
    enabled: hasWizardProfile,
  })

export const useUserReviewMutation = () =>
  useMutation<void, Error, UserReview>(
    async (userReview) => saveUserReview(userReview),
    {
      onSuccess: (_, userReview) => {
        queryClient.invalidateQueries(['booking', userReview.id])
      },
    }
  )

export const useWizardReviewMutation = () =>
  useMutation<void, Error, WizardReview>(
    async (wizardReview) => saveWizardReview(wizardReview),
    {
      onSuccess: (_, wizardReview) => {
        queryClient.invalidateQueries(['booking', wizardReview.id])
      },
    }
  )
