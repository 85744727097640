import React from 'react'
import { Center, Heading, Stack } from '@chakra-ui/react'
import { useUserProfile, useUserProfileMutation } from 'hooks/user'
import WizardInfoForm from 'forms/WizardInfoForm/WizardInfoForm'
import { useNavigate } from 'react-router'
import { ROUTES } from 'ts/siteConstants'

const WizardInfo: React.FC = () => {
  const navigate = useNavigate()
  const { data: profile } = useUserProfile()
  const userProfileMutation = useUserProfileMutation()
  return (
    <Center minH="100vh" overflowY="scroll" m="4">
      <Stack spacing="4" direction="column" maxW="xl" w="full">
        <Heading size="md" alignSelf="center">
          Finally, set your Local Expert profile
        </Heading>
        <WizardInfoForm
          onSubmit={(wizardDetails) =>
            userProfileMutation.mutate(
              {
                user: {
                  id: profile!.id,
                  wizardDetails: {
                    ...wizardDetails,
                    profileReviewed: false,
                    active: false,
                  },
                },
              },
              {
                onSuccess: () => {
                  navigate(ROUTES.home)
                },
              }
            )
          }
        />
      </Stack>
    </Center>
  )
}
export default WizardInfo
