import { Heading, Stack, CircularProgress, Text } from '@chakra-ui/react'
import BookingCard from 'components/BookingCard/BookingCard'
import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import { useBookings, useWizardBookings } from 'hooks/booking'
import { useUserProfile } from 'hooks/user'

const Bookings: React.FC = () => {
  const { data: profile, isLoading: isLoadingProfile } = useUserProfile()
  const { data: userBookings, isLoading: userIsLoading } = useBookings()
  const {
    data: wizardBookings,
    isLoading: wizardIsLoading,
  } = useWizardBookings(profile?.wizardDetails !== undefined)

  const isLoading = userIsLoading || wizardIsLoading || isLoadingProfile
  const bookings = [...(userBookings ?? []), ...(wizardBookings ?? [])]

  return (
    <DefaultLayout>
      <Stack direction="column" spacing={8} align="center">
        <Heading size="lg">Upcoming Meetings</Heading>
        {!isLoading && bookings && bookings.length === 0 && (
          <Text>
            Meeting requests and scheduled meetings will show up here.
          </Text>
        )}
        {wizardBookings &&
          wizardBookings.length > 0 &&
          wizardBookings.map((booking) => (
            <BookingCard key={booking.id} booking={booking} isWizard={true} />
          ))}
        {userBookings &&
          userBookings.length > 0 &&
          userBookings.map((booking) => (
            <BookingCard key={booking.id} booking={booking} isWizard={false} />
          ))}
        {isLoading && <CircularProgress color="blue.500" isIndeterminate />}
      </Stack>
    </DefaultLayout>
  )
}
export default Bookings
