import { Avatar, Skeleton } from '@chakra-ui/react'
import { useUserProfilePhoto } from 'hooks/user'

type Props = {
  userId: string
}

const UserAvatar: React.FC<Props> = ({ userId }) => {
  const {
    data: userProfilePhoto,
    isLoading: isLoadingUserProfilePhoto,
  } = useUserProfilePhoto(userId)
  return isLoadingUserProfilePhoto ? (
    <Skeleton w="fit-content" borderRadius="full">
      <Avatar size="2xl" />
    </Skeleton>
  ) : (
    <Avatar src={userProfilePhoto} size="2xl" />
  )
}
export default UserAvatar
