import React from 'react'
import {
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  BookingAddon,
  BookingAddonType,
  BOOKING_ADDON_DURATION_MINUTES,
  DAYS_OF_WEEK,
  NewBooking,
  calculateBookingCost,
  WizardPublicProfile,
  ADDON_TRANSPORTATION,
  ADDON_FLIGHT_TICKETS,
  BOOKING_ADDONS,
  ADDON_ACCOMMODATION,
  ADDON_ACTIVITIES,
} from '@traviqo/data-model'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useStripeCustomer, useUserProfile } from 'hooks/user'
import { DateTime, Duration, Interval } from 'luxon'
import DatePicker from 'components/DatePicker/DatePicker'
import { InfoIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import { ROUTES } from 'ts/siteConstants'
import AddonForm from 'forms/AddonForm/AddonForm'
import BookingAddonItem, {
  ADDON_DATA,
} from 'components/BookingAddonItem/BookingAddonItem'

const DURATION_MINUTE_OPTIONS = [30, 60]

const ADDON_TEXT = {
  [ADDON_FLIGHT_TICKETS]: `Flight search (${BOOKING_ADDON_DURATION_MINUTES[ADDON_FLIGHT_TICKETS]} min)`,
  [ADDON_TRANSPORTATION]: `Local transportation (${BOOKING_ADDON_DURATION_MINUTES[ADDON_TRANSPORTATION]} min)`,
  [ADDON_ACCOMMODATION]: `Accomodation and lodging (${BOOKING_ADDON_DURATION_MINUTES['accommodation']} min)`,
  [ADDON_ACTIVITIES]: `Daytrips and activities (${BOOKING_ADDON_DURATION_MINUTES['activities']} min)`,
}

const validationSchema = yup.object().shape({
  meetingDate: yup.date().required('Field is required'),
  duration: yup.number().required('Field is required'),
  startTime: yup
    .string()
    .required('Field is required')
    .max(255, 'Please enter a value under 256 characters'),
  destination: yup.string().required('Field is required'),
  whenIsTrip: yup.string().required('Field is required'),
  travelerCount: yup.string().required('Field is required'),
  whatHelpNeeded: yup.string().required('Field is required'),
  bookingAddons: yup.array().required('Field is required'),
})

interface Props {
  wizard: WizardPublicProfile
  onSubmit?: (
    newBooking: Omit<NewBooking, 'wizardId' | 'wizard' | 'user' | 'fee'>
  ) => void
}

const BookWizardForm: React.FC<Props> = ({ wizard, onSubmit }) => {
  const { data: profile } = useUserProfile()
  const [activeAddon, setActiveAddon] = React.useState<
    undefined | BookingAddonType
  >()
  const {
    data: stripeCustomer,
    isLoading: isLoadingStripeCustomer,
  } = useStripeCustomer()
  const formik = useFormik({
    initialValues: {
      meetingDate: undefined as Date | undefined,
      duration: DURATION_MINUTE_OPTIONS[0],
      startTime: '',
      destination: '',
      whenIsTrip: '',
      travelerCount: '',
      whatHelpNeeded: '',
      bookingAddons: [] as BookingAddon[],
    },
    validationSchema,
    onSubmit: (values) => {
      const dateTime = values.meetingDate!
      dateTime.setHours(Number(values.startTime.split(':')[0]))
      dateTime.setMinutes(Number(values.startTime.split(':')[1]))
      onSubmit?.({
        dateTime: dateTime.toISOString(),
        duration: values.duration,
        destination: values.destination,
        whenIsTrip: values.whenIsTrip,
        travelerCount: values.travelerCount,
        whatHelpNeeded: values.whatHelpNeeded,
        bookingAddons: values.bookingAddons,
      })
    },
  })

  const availableIntervals = [...Array(7).keys()].map((day) => {
    const wizardTimes = wizard.availableDaysOfWeek[DAYS_OF_WEEK[day]]?.[0]
    if (!wizardTimes || !profile) {
      return undefined
    }
    const start = DateTime.fromObject(
      {
        weekday: day === 0 ? 7 : day,
        hour: Number(wizardTimes.start.split(':')[0]),
        minute: Number(wizardTimes.start.split(':')[1]),
      },
      {
        zone: wizard.timezone.value,
      }
    )
    const end = DateTime.fromObject(
      {
        weekday: day === 0 ? 7 : day,
        hour: Number(wizardTimes.end.split(':')[0]),
        minute: Number(wizardTimes.end.split(':')[1]),
      },
      {
        zone: wizard.timezone.value,
      }
    )
    return Interval.fromDateTimes(
      start.setZone(profile.basicDetails!.timezone.value),
      end
        .setZone(profile.basicDetails!.timezone.value)
        .minus(Duration.fromObject({ minutes: formik.values.duration }))
    )
  })

  const isWizardAvailable = (date: Date) => {
    const interval = Interval.fromDateTimes(
      DateTime.fromObject({
        weekday: date.getDay() === 0 ? 7 : date.getDay(),
      }).startOf('day'),
      DateTime.fromObject({
        weekday: date.getDay() === 0 ? 7 : date.getDay(),
      }).endOf('day')
    )
    // Don't allow dates in past
    if (
      date < new Date() ||
      (date === new Date() && interval.end < DateTime.now())
    ) {
      return false
    }

    return (
      !!availableIntervals &&
      availableIntervals.some(
        (availableInterval) =>
          !!availableInterval && availableInterval!.overlaps(interval)
      )
    )
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Stack direction="column" spacing="4">
          <FormControl isRequired>
            <FormLabel>
              Meeting Duration
              <Tooltip
                label="During this online meeting you can tell the local expert a bit more in detail about your wishes and what kind of help you’d like. If you would also like some general background info about your destination, it’s useful to choose 60 minutes."
                zIndex="99999"
              >
                <QuestionOutlineIcon
                  boxSize={4}
                  verticalAlign="top"
                  mt={0.5}
                  ml={0.5}
                />
              </Tooltip>
            </FormLabel>
            <Select
              value={formik.values.duration}
              onChange={(e) =>
                formik.setFieldValue('duration', Number(e.target.value))
              }
              w="full"
            >
              {DURATION_MINUTE_OPTIONS.map((duration) => (
                <option key={duration} value={duration}>
                  {duration} minutes
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              !!formik.errors.meetingDate && formik.touched.meetingDate
            }
          >
            <FormLabel>When would you like to meet?</FormLabel>
            <DatePicker
              selected={formik.values.meetingDate}
              onChange={(date) => {
                date === undefined && formik.setFieldValue('startTime', '')
                formik.setFieldValue('meetingDate', date)
              }}
              filterDate={isWizardAvailable}
              placeholderText="Select a date"
            />
            <FormErrorMessage>{formik.errors.meetingDate}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!formik.errors.startTime && formik.touched.startTime}
          >
            <FormLabel>At what time?</FormLabel>
            <Select
              value={formik.values.startTime}
              onChange={(e) =>
                formik.setFieldValue('startTime', e.target.value)
              }
              placeholder="Select option"
              disabled={!formik.values.meetingDate}
              w="full"
            >
              {/** Better timezone support, check for day overlaps */}
              {formik.values.meetingDate &&
                availableIntervals[formik.values.meetingDate!.getDay()]
                  ?.splitBy({ minutes: 15 })
                  .map((interval) => (
                    <option
                      key={interval.start.toISOTime()}
                      value={interval.start.toISOTime().slice(0, 5)}
                    >
                      {interval.start.toISOTime().slice(0, 5)}
                    </option>
                  ))}
            </Select>
            <FormErrorMessage>{formik.errors.startTime}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              !!formik.errors.destination && formik.touched.destination
            }
          >
            <FormLabel>
              Where will you travel?
              <Tooltip
                label="No worries if you don’t have a specific destination in mind. You can also just say you’d like to go to a sandy sunny beach, or a spot to learn scuba diving."
                zIndex="99999"
              >
                <QuestionOutlineIcon
                  boxSize={4}
                  verticalAlign="top"
                  mt={0.5}
                  ml={0.5}
                />
              </Tooltip>
            </FormLabel>
            <Input
              name="destination"
              onChange={formik.handleChange}
              value={formik.values.destination}
            />
            <FormErrorMessage>{formik.errors.destination}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!formik.errors.whenIsTrip && formik.touched.whenIsTrip}
          >
            <FormLabel>
              When will you travel?
              <Tooltip
                label="Here as well, it is not necessary to provide exact dates. You can also say: coming Summer."
                zIndex="99999"
              >
                <QuestionOutlineIcon
                  boxSize={4}
                  verticalAlign="top"
                  mt={0.5}
                  ml={0.5}
                />
              </Tooltip>
            </FormLabel>
            <Input
              name="whenIsTrip"
              onChange={formik.handleChange}
              value={formik.values.whenIsTrip}
            />
            <FormErrorMessage>{formik.errors.whenIsTrip}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              !!formik.errors.travelerCount && formik.touched.travelerCount
            }
          >
            <FormLabel>How many people are traveling?</FormLabel>
            <Input
              name="travelerCount"
              onChange={formik.handleChange}
              value={formik.values.travelerCount}
            />
            <FormErrorMessage>{formik.errors.travelerCount}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={
              !!formik.errors.whatHelpNeeded && formik.touched.whatHelpNeeded
            }
          >
            <FormLabel>Need help with something specific?</FormLabel>
            <Input
              name="whatHelpNeeded"
              onChange={formik.handleChange}
              value={formik.values.whatHelpNeeded}
            />
            <FormErrorMessage>{formik.errors.whatHelpNeeded}</FormErrorMessage>
          </FormControl>
          {/* default to show all when undefined */}
          {(wizard.offeredAddons?.length ?? 1) > 0 && (
            <FormLabel>
              {wizard.firstName} can help you with:
              <Tooltip
                label="You can select multiple options. So for example, if you’d like your local expert to look for two different kinds of accommodations in two cities, just select “Accommodations and lodging” twice. The indicated time behind it shows how much time we calculate to do this."
                zIndex="99999"
              >
                <QuestionOutlineIcon
                  boxSize={4}
                  verticalAlign="top"
                  mt={0.5}
                  ml={0.5}
                />
              </Tooltip>
            </FormLabel>
          )}
          {BOOKING_ADDONS.map((addon) => (
            <Button
              key={addon}
              w="full"
              variant="outline"
              onClick={() => setActiveAddon(addon)}
              disabled={
                wizard.offeredAddons === null ||
                wizard.offeredAddons === undefined
                  ? false
                  : !wizard.offeredAddons?.includes(addon)
              }
            >
              {ADDON_TEXT[addon]}
            </Button>
          ))}
          {formik.values.bookingAddons.length > 0 && (
            <Heading size="sm">Selected services</Heading>
          )}
          {formik.values.bookingAddons.map((addon, idx) => (
            <BookingAddonItem
              key={addon.type + addon.additionalInfo + idx}
              addon={addon}
              onRemove={() =>
                formik.setFieldValue('bookingAddons', [
                  ...formik.values.bookingAddons.slice(0, idx),
                  ...formik.values.bookingAddons.slice(idx + 1),
                ])
              }
            />
          ))}
          {isLoadingStripeCustomer && (
            <CircularProgress color="blue.500" isIndeterminate />
          )}
          {!stripeCustomer?.paymentsEnabled && (
            <>
              <Text>
                Choose a payment option on your profile to submit your request
                <Tooltip
                  label="We’ll only finalize the payment after you receive the travel itinerary. If you are not satisfied with the results, you don’t need to pay. We use Stripe to process payments."
                  zIndex="99999"
                >
                  <QuestionOutlineIcon
                    boxSize={4}
                    verticalAlign="top"
                    mt={0.5}
                    ml={0.5}
                  />
                </Tooltip>
              </Text>
              <Button as={RouterLink} to={ROUTES.profile} target="_blank">
                Select payment option
              </Button>
            </>
          )}
          <Text textAlign="center">
            Total: €
            <b>
              {calculateBookingCost(
                wizard.rate,
                formik.values.duration,
                formik.values.bookingAddons
              ).toFixed(2)}
            </b>
          </Text>
          <Flex direction="row" justify="center">
            <InfoIcon m={1} />
            <Text>You will only be charged after the meeting</Text>
          </Flex>
          <Button
            type="submit"
            size="lg"
            isLoading={formik.isSubmitting}
            isDisabled={!formik.dirty || !stripeCustomer?.paymentsEnabled}
          >
            Submit Request
          </Button>
        </Stack>
      </form>
      <Modal
        isOpen={activeAddon !== undefined}
        onClose={() => setActiveAddon(undefined)}
      >
        <ModalOverlay />
        <ModalContent>
          {activeAddon !== undefined && (
            <>
              <ModalHeader>{ADDON_DATA[activeAddon].label}</ModalHeader>
              <ModalCloseButton />
              <ModalBody mb={4}>
                <AddonForm
                  helpText={ADDON_DATA[activeAddon].helpText}
                  onSubmit={(value) => {
                    formik.setFieldValue('bookingAddons', [
                      ...formik.values.bookingAddons,
                      {
                        type: activeAddon,
                        additionalInfo: value.additionalInfo,
                      } as BookingAddon,
                    ])
                    setActiveAddon(undefined)
                  }}
                />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
export default BookWizardForm
