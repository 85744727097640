import React, { FormEventHandler } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Button } from '@chakra-ui/button'
import { Heading } from '@chakra-ui/layout'
import { ROUTES } from 'ts/siteConstants'

const PaymentsForm: React.FC = () => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.origin}${ROUTES.profile}`,
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log('success')
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Heading mb={4} size="sm">
        Payment Info
      </Heading>
      <PaymentElement />
      <Button disabled={!stripe} w="fit-content" mt={4} type="submit">
        Submit
      </Button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}
export default PaymentsForm
