export const REVIEW_COLLECTION_KEY = 'root'

export interface PublicReview {
  bookingId: string
  stars: number
  reviewText: string
  reviewerId: string
  reviewerName: string
}

export interface Reviews {
  reviews: PublicReview[]
}
