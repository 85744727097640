import { ThemeOverride } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

const textStyles = {
  h1: {
    fontSize: { base: '5xl', sm: '7xl' },
    lineHeight: 1,
    fontWeight: 'medium',
    fontFamily: 'heading',
    //color: 'blue.900',
  },
  h2: {
    fontSize: { base: '4xl', sm: '6xl' },
    lineHeight: 'short',
    fontWeight: 'bold',
    fontFamily: 'heading',
    //color: 'green.500',
  },
  h3: {
    fontSize: { base: '3xl', sm: '5xl' },
    lineHeight: 'short',
    fontWeight: 'bold',
    fontFamily: 'heading',
    //color: 'blue.900',
  },
  h4: {
    fontSize: { base: 'xl', sm: '3xl' },
    lineHeight: 'short',
    fontWeight: 'medium',
    fontFamily: 'headingSmall',
    //color: 'green.500',
  },
  p: {
    fontSize: { base: 'lg', sm: '2xl' },
    lineHeight: { base: 'short' },
    fontWeight: 'normal',
    fontFamily: 'body',
    //color: 'gray.900',
  },
  pLight: {
    fontSize: { base: 'lg', sm: '2xl' },
    lineHeight: { base: 'short' },
    fontWeight: 'normal',
    fontFamily: 'body',
    //color: 'gray.400',
  },
  inputLabel: {
    fontSize: { base: 'md', sm: 'xl' },
    lineHeight: { base: 'short' },
    fontWeight: 'medium',
    fontFamily: 'headingSmall',
    //color: 'gray.900',
  },
}

const theme = {
  colors: {
    gray: {
      50: '#FCFAF8', //
      100: '#F2F2F2', //
      200: '#C1C0C4',
      300: '#CECDD2', //
      400: '#8B8888',
      500: '#5D5C61', //
      600: '#535257',
      700: '#49484C',
      800: '#3F3E42',
      900: '#3D3D3D', //
    },
    red: {
      50: '#FF7173',
      100: '#FF5C5F',
      200: '#FF3337',
      300: '#FF0B0F',
      400: '#E10004',
      500: '#B80003',
      600: '#9F0003',
      700: '#850002',
      800: '#6C0002',
      900: '#520001',
    },
    green: {
      50: '#DCE5E5',
      100: '#D0DCDC',
      200: '#B8CBCA',
      300: '#A1BAB9',
      400: '#89A8A7',
      500: '#729796',
      600: '#597877',
      700: '#415857',
      800: '#293837',
      900: '#111717',
    },
    blue: {
      50: '#FFFFFF',
      100: '#F1F5F8',
      200: '#D6E2EB',
      300: '#BBCFDD',
      400: '#9FBBD0',
      500: '#84A8C2',
      600: '#5E8DAF',
      700: '#47718F',
      800: '#345369',
      900: '#294C65',
    },
    alertGreen: {
      50: '#78E3C1',
      100: '#67DFB9',
      200: '#45D8AA',
      300: '#2ACB98',
      400: '#23A97E',
      500: '#1C8765',
      600: '#187255',
      700: '#135D45',
      800: '#0F4836',
      900: '#0A3326',
    },
  },
  semanticTokens: {
    colors: {
      error: 'red.500',
      success: 'alertGreen.500',
      blue: 'blue.500',
      darkBlue: 'blue.900',
      gray: 'gray.500',
      green: 'green.500',
      lightBorder: '#EBF1F5',
    },
  },
  fonts: {
    heading: 'Arboria-bold, sans-serif',
    headingSmall: 'Arboria-medium, sans-serif',
    body: 'Arboria-book, sans-serif',
    mono: 'monospace',
  },
  textStyles,
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem', // 20
    '2xl': '1.5rem', // 24
    '3xl': '1.75rem', // 28
    '4xl': '2.25rem',
    '5xl': '2.75rem', // 44
    '6xl': '3.625rem', // 58
    '7xl': '4.5rem', // 72
    '8xl': '6rem',
    '9xl': '8rem',
  },
  radii: {
    '4xl': '2.375rem',
  },
  components: {
    Avatar: {
      baseStyle: () => ({
        container: {
          backgroundColor: 'blue.500',
          backgroundClip: 'content-box',
          padding: '4px',
          borderColor: 'blue.300',
          borderWidth: '4px',
          borderStyle: 'solid',
        },
      }),
    },
    Button: {
      variants: {
        solid: (props: StyleFunctionProps) => {
          const { colorScheme: c } = props
          return {
            _hover: {
              backgroundColor: c === 'blue' ? `${c}.900` : undefined,
            },
          }
        },
      },
      defaultProps: {
        colorScheme: 'green',
      },
      baseStyle: {
        borderRadius: 'full',
        lineHeight: 'none',
      },
    },
    Divider: {
      baseStyle: {
        borderWidth: '2px',
        color: 'blue.900',
        opacity: '.3',
      },
    },
    Heading: {
      sizes: {
        xl: { ...textStyles.h1 },
        lg: { ...textStyles.h2 },
        md: { ...textStyles.h3 },
        sm: { ...textStyles.h4 },
      },
    },
  },
} as ThemeOverride

export default theme
