import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  additionalInfo: yup.string().required('Field is required'),
})

type Props = {
  helpText: string
  onSubmit: (value: { additionalInfo: string }) => void
}

const AddonForm: React.FC<Props> = ({ helpText, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      additionalInfo: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack direction="column" spacing="4">
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.additionalInfo && formik.touched.additionalInfo
          }
        >
          <FormLabel>{helpText}</FormLabel>
          <Input
            name="additionalInfo"
            onChange={formik.handleChange}
            value={formik.values.additionalInfo}
            autoFocus
          />
          <FormErrorMessage>{formik.errors.additionalInfo}</FormErrorMessage>
        </FormControl>
        <Button type="submit" isDisabled={formik.values.additionalInfo === ''}>
          Save
        </Button>
      </Stack>
    </form>
  )
}
export default AddonForm
