import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

export const firebaseConfigProd = {
  apiKey: 'AIzaSyDxRSG_4l_SSUoa-KtmZGjJhET0LtyVenI',
  authDomain: 'traviqo-prod.firebaseapp.com',
  projectId: 'traviqo-prod',
  storageBucket: 'traviqo-prod.appspot.com',
  messagingSenderId: '534044627467',
  appId: '1:534044627467:web:d410e21f486b8d40332d0e',
}

export const firebaseConfigStaging = {
  apiKey: 'AIzaSyBSerhJ2vPiRhgwM2DYipyC3uoaIuwYTS4',
  authDomain: 'traviqo-staging.firebaseapp.com',
  projectId: 'traviqo-staging',
  storageBucket: 'traviqo-staging.appspot.com',
  messagingSenderId: '188439770113',
  appId: '1:188439770113:web:9372bd3a0d8dfa0fd4fc00',
}

export let firebaseConfig = firebaseConfigStaging
if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
  console.log('Prod Env')
  firebaseConfig = firebaseConfigProd
} else {
  console.log('Staging Env in browser')
}

export const firebaseApp = initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  console.log('Using local firebase auth.')
  const auth = getAuth()
  connectAuthEmulator(auth, 'http://localhost:9099')

  console.log('Using local firebase storage.')
  const storage = getStorage()
  connectStorageEmulator(storage, 'localhost', 9199)
}
