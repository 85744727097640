import React from 'react'
import {
  Box,
  Stack,
  Flex,
  Text,
  CircularProgress,
  Button,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react'
import { Heading } from '@chakra-ui/layout'
import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import { useLocatioinsAutoComplete, useWizards } from 'hooks/user'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { ROUTES } from 'ts/siteConstants'
import UserAvatar from 'components/UserAvatar/UserAvatar'
import GoogleMap from 'components/GoogleMap/GoogleMap'
import { Select } from 'chakra-react-select'
import { useDebounce } from 'usehooks-ts'
import googleLogo from 'assets/google_on_white.png'

const TravelerHome: React.FC = () => {
  const [locationSearch, setLocationSearch] = React.useState('')
  const debouncedLocationSearch = useDebounce(locationSearch, 500)
  const {
    data: locationsAutoComplete,
    isLoading: isLoadingAutoComplete,
  } = useLocatioinsAutoComplete(debouncedLocationSearch)
  const [activeSearch, setActiveSearch] = React.useState<{
    location?: string
    locationId?: string
  }>({})

  const [wizardIds, setWizardIds] = React.useState<string[]>()
  const { data: mapWizards, isLoading: isLoadingMap } = useWizards({
    wizardIds,
  })
  const { data: listWizards, isLoading: isLoadingList } = useWizards({
    location: activeSearch.locationId,
  })
  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <DefaultLayout>
      <Stack direction="column" spacing={8} align="center">
        <Heading size="md" mt={12} textAlign="center">
          Select a local expert to plan your next holiday adventure
        </Heading>
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          w="full"
          align="center"
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab>Map Search</Tab>
            <Tab>List Search</Tab>
          </TabList>
          <TabPanels w="full">
            <TabPanel w="full" px={0} py={4}>
              <>
                <GoogleMap
                  onSelection={(ids) => {
                    setWizardIds(ids)
                  }}
                />
                {!wizardIds && (
                  <Heading size="sm" mt={4}>
                    Click on a pin to see the available Locals
                  </Heading>
                )}
              </>
            </TabPanel>
            <TabPanel w="full" px={0} py={4}>
              <>
                <Box w="full" maxW="3xl" px={4}>
                  <Select
                    isLoading={isLoadingAutoComplete}
                    options={locationsAutoComplete?.map((option) => ({
                      value: option.locationId,
                      label: option.label,
                    }))}
                    placeholder="Enter a city..."
                    value={{
                      value: activeSearch?.locationId,
                      label: activeSearch?.location,
                    }}
                    menuIsOpen={
                      locationSearch.length > 2 &&
                      !isLoadingAutoComplete &&
                      locationSearch === debouncedLocationSearch
                    }
                    blurInputOnSelect
                    isMulti={false}
                    inputValue={locationSearch}
                    onInputChange={(value) => setLocationSearch(value)}
                    onChange={(newValue) =>
                      setActiveSearch({
                        location: newValue?.label,
                        locationId: newValue?.value,
                      })
                    }
                    useBasicStyles
                    chakraStyles={{
                      dropdownIndicator: () => ({
                        display: 'none',
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        _after: {
                          height: '18px',
                          content: '""',
                          display: 'block',
                          background: `transparent url(${googleLogo}) no-repeat center`,
                        },
                      }),
                    }}
                  />
                </Box>
                {!activeSearch.locationId && (
                  <Heading size="sm" mt={4}>
                    Enter a city to see nearby Locals
                  </Heading>
                )}
              </>
            </TabPanel>
          </TabPanels>
        </Tabs>
        {(tabIndex === 0
          ? mapWizards !== undefined && mapWizards.length === 0
          : listWizards !== undefined && listWizards.length === 0) && (
          <Heading size="sm">No wizards found</Heading>
        )}
        {(tabIndex === 0
          ? mapWizards !== undefined
          : listWizards !== undefined) &&
          (tabIndex === 0 ? mapWizards! : listWizards!).map((wizard) => (
            <Box
              w="full"
              p={{ base: 4, sm: 8 }}
              key={wizard.id}
              borderRadius="4xl"
              border="2px solid"
              borderColor="lightGray"
              id="map-results"
            >
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                spacing={{ base: 2, sm: 0 }}
                w="full"
                align="center"
              >
                <UserAvatar userId={wizard.id} />
                {/*
                  <ImageGallery images={wizard.images} />
                  */}
                <Stack
                  direction="column"
                  pl={{ base: 0, sm: 4 }}
                  spacing={{ base: 2, sm: 1 }}
                  w="3xs"
                  flexGrow={1}
                  align={{ base: 'center', sm: 'flex-start' }}
                >
                  <Heading size="sm" color="green">
                    {`${wizard.firstName} ${wizard.lastName}`}
                  </Heading>
                  {/*
                  <Flex direction="row" align="center">
                    <Image src={LocationIcon} w="24px" pr={1} />
                    <Heading size="xs">
                      {`${wizard.cityState ?? wizard.city}, ${wizard.country}`}
                    </Heading>
                  </Flex>
                  */}
                  <Text
                    textOverflow="ellipsis"
                    display="-webkit-box"
                    overflow="hidden"
                    style={{
                      WebkitLineClamp: 6,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    <b>About: </b>
                    {wizard.about}
                  </Text>
                  <Text>
                    <b>Knows about: </b>
                    {wizard.adviceRegionsList
                      ?.map((region) => region.label)
                      ?.join(' | ') ?? wizard.adviceRegions}
                  </Text>
                </Stack>
                <Stack
                  direction="column"
                  maxW={48}
                  spacing={4}
                  align="flex-end"
                >
                  <Flex direction="row" align="flex-end">
                    <Text textStyle="h4">€{wizard.rate}</Text>
                    <Text fontSize="xl" opacity=".5" ml={2}>
                      / hour
                    </Text>
                  </Flex>
                  <Button
                    as={RouterLink}
                    to={generatePath(ROUTES.wizardDetail, {
                      wizardId: wizard.id,
                    })}
                    w="full"
                    colorScheme="green"
                    target="_blank"
                  >
                    PROFILE
                  </Button>
                </Stack>
              </Stack>
            </Box>
          ))}
        {(isLoadingMap || isLoadingList) && (
          <CircularProgress color="blue.500" isIndeterminate />
        )}
      </Stack>
    </DefaultLayout>
  )
}
export default TravelerHome
