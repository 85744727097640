import React from 'react'
import { CircularProgress } from '@chakra-ui/progress'
import {
  Booking,
  calculateBookingCost,
  PublicBasicUserDetails,
  WizardPublicProfile,
} from '@traviqo/data-model'
import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import NotFound from 'components/NotFound/NotFound'
import {
  useAcceptBookingMutation,
  useBooking,
  useCancelBookingMutation,
  useDeclineBookingMutation,
  useUserReviewMutation,
  useWizardReviewMutation,
} from 'hooks/booking'
import { useUserProfile } from 'hooks/user'
import { useMatch } from 'react-router'
import { ROUTES } from 'ts/siteConstants'
import { Stack, Heading, Text, Center } from '@chakra-ui/layout'
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Image,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import AcceptBookingForm from 'forms/AcceptBookingForm/AcceptBookingForm'
import { CheckCircleIcon, CloseIcon, InfoIcon } from '@chakra-ui/icons'
import UserReviewForm from 'forms/UserReviewForm/UserReviewForm'
import WizardReviewForm from 'forms/WizardReviewForm/WizardReviewForm'
import UserAvatar from 'components/UserAvatar/UserAvatar'
import LocationIcon from 'assets/icons/location.svg'
import BookingAddonItem from 'components/BookingAddonItem/BookingAddonItem'

const DECLINE_MEETING_MESSAGE = 'Decline Meeting'
const CANCEL_MEETING_MESSAGE = 'Cancel Meeting'

const BookingDetail: React.FC = () => {
  const { data: profile, isLoading: isLoadingProfile } = useUserProfile()
  const match = useMatch(ROUTES.bookingDetail)
  const { data: booking, isLoading: isLoadingBookings, error } = useBooking(
    match?.params.bookingId
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [alertBody, setAlertBody] = React.useState('')
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const acceptBookingMutation = useAcceptBookingMutation()
  const declineBookingMutation = useDeclineBookingMutation()
  const cancelBookingMutation = useCancelBookingMutation()
  const userReviewMutation = useUserReviewMutation()
  const wizardReviewMutation = useWizardReviewMutation()
  const toast = useToast({
    position: 'bottom',
    status: 'success',
    duration: 4000,
    isClosable: true,
  })

  const {
    isOpen: isReviewOpen,
    onOpen: onReviewOpen,
    onClose: onReviewClose,
  } = useDisclosure()

  if (!match || !match?.params.bookingId || error) {
    return <NotFound message="Booking not found" />
  }

  const isUser = booking?.userId === profile?.id
  const isWizard = !isUser

  const otherUser: PublicBasicUserDetails | WizardPublicProfile = isUser
    ? (booking as Booking<true>)?.wizard
    : (booking as Booking<false>)?.user

  const otherUserName =
    booking && profile && otherUser.firstName + ' ' + otherUser.lastName

  const bookingDetailsFields = booking && [
    {
      label: 'Meeting URL',
      value:
        booking.meetingUrl ??
        `You'll receive the link for the meeting once ${
          isUser
            ? otherUserName
            : `${profile?.basicDetails?.firstName} ${profile?.basicDetails?.lastName}`
        } accepts`,
    },
    {
      label: 'Your destination',
      value: booking.destination,
    },
    {
      label: 'Travel date',
      value: booking.whenIsTrip,
    },
    {
      label: 'Number of people ',
      value: booking.travelerCount,
    },
    {
      label: 'Specific requests',
      value: booking.whatHelpNeeded,
    },
  ]

  return (
    <DefaultLayout>
      {(isLoadingBookings || isLoadingProfile) && (
        <Center>
          <CircularProgress color="blue.500" isIndeterminate />
        </Center>
      )}
      {booking && profile && (
        <Stack direction="column" spacing={4}>
          <Heading size="md" textAlign="center">
            About your chat with {otherUserName}
          </Heading>
          <Box
            w="full"
            p={{ base: 4, sm: 8 }}
            borderRadius="4xl"
            border="2px solid"
            borderColor="lightGray"
          >
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              spacing={{ base: 2, sm: 0 }}
              w="full"
              align="center"
            >
              <UserAvatar
                userId={isWizard ? booking.userId : booking.wizardId}
              />
              <Stack
                direction="column"
                pl={{ base: 0, sm: 4 }}
                spacing={{ base: 2, sm: 1 }}
                w={{ base: 'full', sm: '3xs' }}
                flexGrow={1}
                align={{ base: 'center', sm: 'flex-start' }}
              >
                <Heading size="sm" color="green">
                  {otherUserName}
                </Heading>
                <Flex direction="row" align="center">
                  <Image src={LocationIcon} w="24px" pr={1} />
                  <Heading size="xs">
                    {isWizard
                      ? `${
                          (booking as Booking<false>).user.cityState ??
                          (booking as Booking<false>).user.city
                        }, ${(booking as Booking<false>).user.country}`
                      : `${
                          (booking as Booking<true>).wizard.cityState ??
                          (booking as Booking<true>).wizard.city
                        }, ${(booking as Booking<true>).wizard.country}`}
                  </Heading>
                </Flex>
                <Text>
                  <b>About: </b>
                  {isWizard
                    ? (booking as Booking<false>).user.about
                    : (booking as Booking<true>).wizard.about}
                </Text>
              </Stack>
              <Box height={44} display={{ base: 'none', sm: 'block' }}>
                <Divider orientation="vertical" height="full" mx={8} />
              </Box>
              <Stack direction="column" spacing={2} align="center" flexGrow={1}>
                <Text textStyle="h4">
                  {new Date(booking.dateTime).toLocaleString([], {
                    dateStyle: 'long',
                    timeStyle: 'short',
                  })}
                </Text>
                {!isWizard && (
                  <Flex
                    direction="row"
                    align={{ base: 'center', sm: 'flex-end' }}
                  >
                    <Text textStyle="h4">
                      €
                      {booking.totalFee ??
                        calculateBookingCost(
                          booking.fee,
                          booking.duration,
                          booking.bookingAddons
                        )}
                    </Text>
                    <Text fontSize="xl" ml={2}>
                      {' '}
                      to be charged after booking
                    </Text>
                  </Flex>
                )}
                <Stack direction="row" spacing={2} align="center">
                  {booking.completed ? (
                    <>
                      <CheckCircleIcon color="green.500" />
                      <Text color="green.500">Meeting Completed</Text>
                    </>
                  ) : booking.canceledTime ? (
                    <>
                      <CloseIcon />
                      <Text>Meeting Canceled</Text>
                    </>
                  ) : booking?.declinedTime ? (
                    <>
                      <CloseIcon />
                      <Text>Meeting Declined</Text>
                    </>
                  ) : booking.acceptedTime ? (
                    <>
                      <CheckCircleIcon color="green.500" />
                      <Text color="green.500">Meeting Accepted</Text>
                    </>
                  ) : (
                    <>
                      <InfoIcon />
                      <Text>Waiting for {otherUserName} to Accept</Text>
                    </>
                  )}
                </Stack>
                <Stack direction="row" spacing={4}>
                  {booking.declinedTime ||
                  booking.canceledTime ||
                  booking.isReviewedByUser ? null : !isUser &&
                    !booking.acceptedTime ? (
                    <>
                      <Button variant="solid" onClick={() => onOpen()}>
                        Accept
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          setAlertBody(DECLINE_MEETING_MESSAGE)
                          onAlertOpen()
                        }}
                      >
                        Decline
                      </Button>
                    </>
                  ) : new Date(booking.dateTime) < new Date() ||
                    process.env.REACT_APP_FIREBASE_ENV === 'staging' ? (
                    <>
                      <Button
                        onClick={() => {
                          setAlertBody(CANCEL_MEETING_MESSAGE)
                          onAlertOpen()
                        }}
                        variant="outline"
                      >
                        Cancel Meeting
                      </Button>
                      {isUser ? (
                        <Button variant="solid" onClick={() => onReviewOpen()}>
                          Leave {otherUserName} a review
                        </Button>
                      ) : (
                        <Button variant="solid" onClick={() => onReviewOpen()}>
                          Mark meeting completed
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        setAlertBody(CANCEL_MEETING_MESSAGE)
                        onAlertOpen()
                      }}
                    >
                      Cancel Meeting
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box
            w="full"
            p={8}
            borderRadius="4xl"
            border="2px solid"
            borderColor="lightGray"
          >
            <Stack direction="column" spacing={4}>
              <Heading size="sm">Meeting Details</Heading>
              {bookingDetailsFields?.map((field) => (
                <React.Fragment key={field.label}>
                  <Text textStyle="inputLabel">{field.label}</Text>
                  <Text>{field.value}</Text>
                </React.Fragment>
              ))}
            </Stack>
          </Box>
          {(booking.bookingAddons?.length ?? 0) > 0 && (
            <Box
              w="full"
              p={8}
              borderRadius="4xl"
              border="2px solid"
              borderColor="lightGray"
            >
              <Stack direction="column" spacing={4}>
                <Heading size="sm">Selected services</Heading>
                {booking.bookingAddons?.map((addon, i) => (
                  <BookingAddonItem key={i} addon={addon} />
                ))}
              </Stack>
            </Box>
          )}
          {isWizard && (
            <Box
              w="full"
              p={8}
              borderRadius="4xl"
              border="2px solid"
              borderColor="lightGray"
            >
              <Stack direction="column" spacing={4} maxW="md">
                <Heading size="sm">Payout Totals</Heading>
                <Flex direction="row" justify="space-between">
                  <Text>Booking total:</Text>
                  <Text>
                    €
                    {booking.totalFee ??
                      calculateBookingCost(
                        booking.fee,
                        booking.duration,
                        booking.bookingAddons
                      )}
                  </Text>
                </Flex>
                <Flex direction="row" justify="space-between">
                  <Text>Platform cut (20%):</Text>
                  <Text>
                    - €
                    {(booking.totalFee ??
                      calculateBookingCost(
                        booking.fee,
                        booking.duration,
                        booking.bookingAddons
                      )) * 0.2}
                  </Text>
                </Flex>
                <Flex direction="row" justify="space-between">
                  <Text fontWeight="bold">Total you receive:</Text>
                  <Text fontWeight="bold">
                    €
                    {(booking.totalFee ??
                      calculateBookingCost(
                        booking.fee,
                        booking.duration,
                        booking.bookingAddons
                      )) * 0.8}
                  </Text>
                </Flex>
              </Stack>
            </Box>
          )}
        </Stack>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Wizard</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            <AcceptBookingForm
              bookingId={match?.params.bookingId}
              onSubmit={async (acceptBooking) => {
                await acceptBookingMutation.mutateAsync(acceptBooking)
                toast({ title: 'Meeting Accepted' })
                onClose()
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isReviewOpen} onClose={onReviewClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave {otherUserName} a review</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            {isUser ? (
              <WizardReviewForm
                wizardName={otherUserName ?? 'Local Expert'}
                bookingId={match?.params.bookingId}
                onSubmit={async (wizardReview) => {
                  await wizardReviewMutation.mutateAsync(wizardReview)
                  toast({ title: 'Thank you for your feedback!' })
                  onReviewClose()
                }}
              />
            ) : (
              <UserReviewForm
                bookingId={match?.params.bookingId}
                onSubmit={async (userReview) => {
                  await userReviewMutation.mutateAsync(userReview)
                  toast({ title: 'Thank you for your feedback!' })
                  onReviewClose()
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
        isOpen={isAlertOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>{alertBody}?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogFooter>
            <Button
              variant="outline"
              ref={cancelRef}
              onClick={onAlertClose}
              disabled={
                declineBookingMutation.isLoading ||
                cancelBookingMutation.isLoading
              }
            >
              Back
            </Button>
            <Button
              ml={3}
              onClick={async () => {
                alertBody === DECLINE_MEETING_MESSAGE
                  ? await declineBookingMutation.mutateAsync({
                      id: match.params.bookingId!,
                    })
                  : await cancelBookingMutation.mutateAsync({
                      id: match.params.bookingId!,
                    })
                onAlertClose()
              }}
              isLoading={
                declineBookingMutation.isLoading ||
                cancelBookingMutation.isLoading
              }
            >
              {alertBody}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </DefaultLayout>
  )
}
export default BookingDetail
