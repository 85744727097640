import React from 'react'
import { Box, CircularProgress } from '@chakra-ui/react'
import { useStripeCustomerSetupIntent } from 'hooks/user'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentsForm from './PaymentsForm'

const STRIPE_PUBLIC_KEY =
  process.env.REACT_APP_FIREBASE_ENV === 'production'
    ? 'pk_live_51L1X03ALpJny1vKQA6wM7ip80ymyRCA4s3qFNF4EQRoLMuh1qiHwdD1zuGyyn2XTAEGSNyw63VL46PoJbfK9uULP00EK9bGU0f'
    : 'pk_test_51L1X03ALpJny1vKQjLsAGFYsIg2mXm3i9zXLyiw1hmqQ28MLy9CPePmUbXlTMY3uzLYauMWsgtiRmY6P3JQ7Fu7r00pYvyNYuh'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const SetupPayments: React.FC = () => {
  const {
    data: setupIntent,
    isLoading: isLoadingSetupIntent,
  } = useStripeCustomerSetupIntent()
  const options = {
    clientSecret: setupIntent?.client_secret,
  }
  return (
    <Box>
      {isLoadingSetupIntent && (
        <CircularProgress color="blue.500" isIndeterminate />
      )}
      {setupIntent && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentsForm />
        </Elements>
      )}
    </Box>
  )
}
export default SetupPayments
