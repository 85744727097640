import { StarIcon } from '@chakra-ui/icons'
import { Stack } from '@chakra-ui/react'

const MAX_STARS = 5

type Props = {
  value?: number
  onChange?: (newValue: number) => void
}

const StarRating: React.FC<Props> = ({ value, onChange }) => {
  return (
    <Stack direction="row" spacing={4}>
      {[...Array(MAX_STARS).keys()].map((idx) => (
        <StarIcon
          key={idx}
          onClick={() => onChange?.(idx + 1)}
          color={value && value - 1 >= idx ? 'green.500' : ''}
          boxSize={8}
        />
      ))}
    </Stack>
  )
}
export default StarRating
