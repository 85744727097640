import React from 'react'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react'
import { FormikErrors, useFormik } from 'formik'
import * as yup from 'yup'
import { Select as ReactSelect } from 'chakra-react-select'

import {
  WizardUserDetails,
  DAYS_OF_WEEK,
  AvailableDaysOfWeek,
  DEFAULT_TIME_RANGE,
  DaysOfWeek,
  ALL_TIMES,
  BOOKING_ADDONS,
  BOOKING_ADDON_DISPLAY,
  BookingAddonType,
} from '@traviqo/data-model'
import { useDebounce } from 'usehooks-ts'
import { useLocatioinsAutoComplete } from 'hooks/user'
import googleLogo from 'assets/google_on_white.png'
import { QuestionOutlineIcon } from '@chakra-ui/icons'

interface Props {
  initialValues?: WizardUserDetails
  errors?: FormikErrors<WizardUserDetails>
  isLoading?: boolean
  onSubmit?: (
    values: Omit<WizardUserDetails, 'profileReviewed' | 'active'>
  ) => void
}

const validationSchema = yup.object({
  rate: yup
    .number()
    .required('Field is required')
    .positive('Must be greater than zero')
    .test(
      'validCurrencyAmount',
      'must have 2 digits or less after decimal',
      (number) => !!number && /^\d+(\.\d{1,2})?$/.test(number.toString())
    ),
  internalApplicationLetter: yup.string().required('Field is required'),
  adviceRegionsList: yup
    .array()
    .of(yup.object({ label: yup.string(), locationId: yup.string() }))
    .required('Field is required'),
  whereExpert: yup.string().required('Field is required'),
  offeredAddons: yup.array().of(yup.string().oneOf(BOOKING_ADDONS)),
  availableDaysOfWeek: DAYS_OF_WEEK.map((day) => ({
    [day]: yup.array().of(
      yup.object({
        start: yup.string().required('Field is required'),
        end: yup.string().required('Field is required'),
      })
    ),
  })).reduce((result, current) => result.shape(current), yup.object()),
})

const WizardInfoForm: React.FC<Props> = ({
  initialValues,
  errors,
  isLoading,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      rate: initialValues?.rate ?? '',
      internalApplicationLetter: initialValues?.internalApplicationLetter ?? '',
      adviceRegionsList: initialValues?.adviceRegionsList ?? [],
      whereExpert: initialValues?.whereExpert ?? '',
      offeredAddons: initialValues?.offeredAddons ?? [],
      availableDaysOfWeek:
        initialValues?.availableDaysOfWeek ?? ({} as AvailableDaysOfWeek),
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit &&
        onSubmit({
          ...values,
          rate: Number.parseFloat(
            Number.parseFloat(values.rate as string).toFixed(2)
          ),
        })
    },
  })

  const [locationInput, setLocationInput] = React.useState('')
  const debouncedLocationInput = useDebounce(locationInput, 500)
  const {
    data: locationsAutoComplete,
    isLoading: isLoadingAutoComplete,
  } = useLocatioinsAutoComplete(debouncedLocationInput, undefined, 'city')
  React.useEffect(() => {
    errors && formik.setErrors(errors)
  }, [errors, formik])

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack spacing="4">
        <FormControl
          isRequired
          isInvalid={!!formik.errors.rate && formik.touched.rate}
        >
          <FormLabel>
            What would you like to charge per hour? (In €)
            <Tooltip
              label={
                <p>
                  Unfortunately, we can currently only show your rate in euros.
                  You can use Google to lookup current exchange rates.
                </p>
              }
            >
              <QuestionOutlineIcon
                boxSize={4}
                verticalAlign="top"
                mt={0.5}
                ml={0.5}
              />
            </Tooltip>
          </FormLabel>
          <Input
            name="rate"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.rate}
          />
          <FormErrorMessage>{formik.errors.rate}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.adviceRegionsList &&
            !!formik.touched.adviceRegionsList
          }
        >
          <FormLabel>
            Select the region(s) you know well (Max 5)
            <Tooltip label="Where do you really feel at home?">
              <QuestionOutlineIcon
                boxSize={4}
                verticalAlign="top"
                mt={0.5}
                ml={0.5}
              />
            </Tooltip>
          </FormLabel>
          <ReactSelect
            isLoading={isLoadingAutoComplete}
            options={locationsAutoComplete?.map((option) => ({
              value: option.locationId,
              label: option.label,
            }))}
            value={formik.values.adviceRegionsList.map((region) => ({
              value: region.locationId,
              label: region.label,
            }))}
            menuIsOpen={
              locationInput.length > 2 &&
              !isLoadingAutoComplete &&
              locationInput === debouncedLocationInput
            }
            isMulti={true}
            inputValue={locationInput}
            onInputChange={(value) =>
              formik.values.adviceRegionsList.length < 5 &&
              setLocationInput(value)
            }
            onChange={(newValue) =>
              formik.setFieldValue(
                'adviceRegionsList',
                newValue.map((value) => ({
                  locationId: value.value,
                  label: value.label,
                }))
              )
            }
            useBasicStyles
            chakraStyles={{
              dropdownIndicator: () => ({
                display: 'none',
              }),
              menuList: (provided) => ({
                ...provided,
                _after: {
                  height: '18px',
                  content: '""',
                  display: 'block',
                  background: `transparent url(${googleLogo}) no-repeat center`,
                },
              }),
            }}
          />
          <FormErrorMessage>
            {formik.errors.adviceRegionsList as string}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.whereExpert && formik.touched.whereExpert}
        >
          <FormLabel>
            Why are you an expert in these regions? And how can you help
            travelers?
          </FormLabel>
          <Textarea
            name="whereExpert"
            onChange={formik.handleChange}
            value={formik.values.whereExpert}
          />
          <FormErrorMessage>{formik.errors.whereExpert}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.internalApplicationLetter &&
            formik.touched.internalApplicationLetter
          }
        >
          <FormLabel>
            Tell us more about why you are interested (not public, internal
            only)
            <Tooltip label="Please be elaborate here. We sometimes decline users when they leave this field blank or only use a few words.">
              <QuestionOutlineIcon
                boxSize={4}
                verticalAlign="top"
                mt={0.5}
                ml={0.5}
              />
            </Tooltip>
          </FormLabel>
          <Textarea
            name="internalApplicationLetter"
            onChange={formik.handleChange}
            value={formik.values.internalApplicationLetter}
          />
          <FormErrorMessage>
            {formik.errors.internalApplicationLetter}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>What services can you provide?</FormLabel>
          <CheckboxGroup
            value={formik.values.offeredAddons}
            onChange={(newValue: DaysOfWeek[]) =>
              formik.setFieldValue('offeredAddons', newValue)
            }
          >
            <Stack spacing={4} px={4}>
              {BOOKING_ADDONS.map((addon) => (
                <Checkbox value={addon} key={addon}>
                  <b>
                    {BOOKING_ADDON_DISPLAY[addon as BookingAddonType].name}:{' '}
                  </b>
                  {BOOKING_ADDON_DISPLAY[addon as BookingAddonType].description}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>
            On which days are you available to talk to travelers?
          </FormLabel>
          <CheckboxGroup
            value={Object.keys(formik.values.availableDaysOfWeek)}
            onChange={(newValue: DaysOfWeek[]) =>
              formik.setFieldValue(
                'availableDaysOfWeek',
                newValue
                  .map((activeDay) => ({
                    [activeDay]: formik.values.availableDaysOfWeek?.[
                      activeDay
                    ] ?? [DEFAULT_TIME_RANGE],
                  }))
                  .reduce((all, current) => Object.assign(all, current), {})
              )
            }
          >
            <Stack spacing={4} px={4}>
              {DAYS_OF_WEEK.map((day) => (
                <Flex
                  key={day}
                  as={FormControl}
                  isRequired
                  isInvalid={
                    !!formik.errors.availableDaysOfWeek?.[day] &&
                    formik.touched.availableDaysOfWeek?.[day]
                  }
                  direction="row"
                  justify="space-between"
                >
                  <Checkbox value={day} h="88px">
                    {day}
                  </Checkbox>
                  {Object.keys(formik.values.availableDaysOfWeek).includes(
                    day
                  ) && (
                    <Stack direction="row" spacing={4}>
                      <Stack direction="column">
                        <FormLabel mt={2}>Start time</FormLabel>
                        <Select
                          w="180px"
                          value={
                            formik.values.availableDaysOfWeek![day]![0].start
                          }
                          onChange={(e) =>
                            formik.setFieldValue('availableDaysOfWeek', {
                              ...formik.values.availableDaysOfWeek,
                              [day]: [
                                {
                                  ...formik.values.availableDaysOfWeek![
                                    day
                                  ]![0],
                                  start: e.target.value,
                                },
                              ],
                            })
                          }
                        >
                          {ALL_TIMES.map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </Select>
                      </Stack>
                      <Stack direction="column">
                        <FormLabel mt={2}>End time</FormLabel>
                        <Select
                          w="180px"
                          value={
                            formik.values.availableDaysOfWeek![day]![0].end
                          }
                          onChange={(e) =>
                            formik.setFieldValue('availableDaysOfWeek', {
                              ...formik.values.availableDaysOfWeek,
                              [day]: [
                                {
                                  ...formik.values.availableDaysOfWeek![
                                    day
                                  ]![0],
                                  end: e.target.value,
                                },
                              ],
                            })
                          }
                        >
                          {ALL_TIMES.filter((time) => {
                            const [timeHour, timeMin] = time.split(':')
                            const [
                              hour,
                              min,
                            ] = formik.values.availableDaysOfWeek![
                              day
                            ]![0].start.split(':')
                            return (
                              Number(timeHour) > Number(hour) ||
                              (timeHour === hour &&
                                Number(timeMin) > Number(min))
                            )
                          }).map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </Select>
                      </Stack>
                    </Stack>
                  )}
                  <FormErrorMessage>
                    {formik.errors.availableDaysOfWeek?.[day]}
                  </FormErrorMessage>
                </Flex>
              ))}
            </Stack>
          </CheckboxGroup>
        </FormControl>
        <Button
          type="submit"
          size="lg"
          isLoading={isLoading}
          isDisabled={!!initialValues && !formik.dirty}
        >
          {!!initialValues ? 'Save' : 'Create profile'}
        </Button>
      </Stack>
    </form>
  )
}
export default WizardInfoForm
