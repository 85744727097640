import { Button, Center, Heading, Image, Stack } from '@chakra-ui/react'
import { DividerWithText } from 'components/DividerWithText/DividerWithText'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import LoginForm from 'forms/LoginForm/LoginForm'
import { ROUTES } from 'ts/siteConstants'
import { useLoginMutation } from 'hooks/user'
import logo64 from 'assets/icons/logo64.png'

const Login: React.FC = () => {
  const location = useLocation()
  const loginMutation = useLoginMutation()
  return (
    <Center minH="100vh">
      <Stack direction="column" maxW="sm" w="full" m={4} p={4} spacing={4}>
        <Image
          src={logo64}
          alt="Traviqo logo"
          w="fit-content"
          alignSelf="center"
        />
        <Heading size="md" textAlign="center">
          Sign In
        </Heading>
        <LoginForm
          onSubmit={(values) => loginMutation.mutate(values)}
          errors={
            loginMutation.isError ? loginMutation.error.fields : undefined
          }
        />
        <DividerWithText>or</DividerWithText>
        <Button
          as={RouterLink}
          to={{
            pathname: ROUTES.register,
          }}
          state={location.state}
          size="lg"
          w="full"
        >
          Create new account
        </Button>
      </Stack>
    </Center>
  )
}
export default Login
