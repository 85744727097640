import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react'
import { FormFieldsErrors } from '@traviqo/data-model'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as yup from 'yup'

export interface RegisterFields {
  email: string
  password: string
}

interface Props {
  initialValues?: RegisterFields
  errors?: FormFieldsErrors<RegisterFields>
  onSubmit?: (values: RegisterFields) => void
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Please enter a valid email')
    .email('Please enter a valid email'),
  password: yup
    .string()
    .required('Please enter a valid password')
    .min(8, 'Please choose a longer password'),
  termsConditions: yup
    .boolean()
    .isTrue('Please accept the Terms and Conditions'),
})

const RegisterForm: React.FC<Props> = ({ initialValues, errors, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      email: initialValues?.email ?? '',
      password: initialValues?.password ?? '',
      termsConditions: false,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit && onSubmit(values)
    },
  })

  useEffect(() => {
    errors && formik.setErrors(errors)
  }, [errors, formik])

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack spacing="4">
        <FormControl
          isRequired
          isInvalid={!!formik.errors.email && formik.touched.email}
        >
          <FormLabel>Email address</FormLabel>
          <Input
            name="email"
            type="email"
            autoComplete="email"
            required
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.password && formik.touched.password}
        >
          <FormLabel>Password</FormLabel>
          <Input
            name="password"
            type="password"
            autoComplete="current-password"
            required
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.termsConditions && formik.touched.termsConditions
          }
        >
          <Checkbox
            name="termsConditions"
            onChange={formik.handleChange}
            checked={formik.values.termsConditions}
          >
            I agree to the{' '}
            <Link
              href="https://www.traviqo.com/terms-conditions"
              colorScheme="blue"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </Link>
            *
          </Checkbox>
          <FormErrorMessage>{formik.errors.termsConditions}</FormErrorMessage>
        </FormControl>
        <Button type="submit" size="lg">
          Create new account
        </Button>
      </Stack>
    </form>
  )
}
export default RegisterForm
