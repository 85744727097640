import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'
import { firebaseApp } from '@traviqo/firebase-client'
import Login from 'pages/Login/Login'
import Register from 'pages/Register/Register'
import {
  useUser,
  useUserProfile,
  useCreateUserProfileMutation,
} from 'hooks/user'
import { ONBOARDING_PREFIX, ROUTES } from 'ts/siteConstants'
import { CircularProgress } from '@chakra-ui/progress'
import { useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged } from '@firebase/auth'
import { useQueryClient } from 'react-query'
import { Center } from '@chakra-ui/layout'
import { User } from '@traviqo/data-model'
import InitialUserRole from 'pages/onboarding/InitialUserRole/InitialUserRole'
import BasicUserInfo from 'pages/onboarding/BasicUserInfo/BasicUserInfo'
import WizardInfo from 'pages/onboarding/WizardInfo/WizardInfo'
//import WizardHome from 'pages/WizardHome/WizardHome'
import TravelerHome from 'pages/TravelerHome/TravelerHome'
import Profile from 'pages/Profile/Profile'
import WizardDetail from 'pages/WizardDetail/WizardDetail'
import Bookings from 'pages/Bookings/Bookings'
import BookingDetail from 'pages/BookingDetail/BookingDetail'
import Admin from 'pages/Admin/Admin'
import PasswordReset from 'pages/PasswordReset/PasswordReset'
import PasswordUpdate from 'pages/PasswordReset/PasswordUpdate'

console.log(`Using firebase app ${firebaseApp.name}`)

const isAnonymousRoute = (pathname: string) =>
  pathname.startsWith(ROUTES.login) ||
  pathname.startsWith(ROUTES.register) ||
  pathname.startsWith(ROUTES.passwordReset) ||
  pathname.startsWith(ROUTES.passwordUpdate)

type PrivatePageProps = {
  children: React.ReactElement
}

const PrivatePage: React.FC<PrivatePageProps> = ({ children }) => {
  const { data: user } = useUser()
  const { data: profile, isLoading: isLoadingProfile } = useUserProfile()
  const location = useLocation()

  const nextState =
    (location.state as any)?.from === undefined
      ? { ...(location.state as any), from: location.pathname }
      : { ...(location.state as any), from: (location.state as any).from }
  return (
    // if no user, a login is required
    !user ? (
      <Navigate
        replace
        to={{
          pathname: ROUTES.login,
        }}
        state={{ from: location.pathname }}
      />
    ) : // stay on page while loading
    !profile && isLoadingProfile ? (
      <LoadingUserState />
    ) : // If all details are filled out, take to home instead of edit pages
    (location.pathname === ROUTES.roleSelect && !!profile?.role) ||
      (location.pathname === ROUTES.basicInfo && !!profile?.basicDetails) ||
      (location.pathname === ROUTES.wizardInfo && !!profile?.wizardDetails) ? (
      <Navigate
        replace
        to={{
          pathname: ROUTES.home,
        }}
        state={nextState}
      />
    ) : // ignore redirects if we are already on an edit page
    [ROUTES.roleSelect, ROUTES.basicInfo, ROUTES.wizardInfo].includes(
        location.pathname
      ) ? (
      children
    ) : !profile?.role ? (
      <Navigate
        replace
        to={{
          pathname: ROUTES.roleSelect,
        }}
        state={nextState}
      />
    ) : !profile?.basicDetails ? (
      <Navigate
        replace
        to={{
          pathname: ROUTES.basicInfo,
        }}
        state={nextState}
      />
    ) : profile.role === 'wizard' && !profile.wizardDetails ? (
      <Navigate
        replace
        to={{
          pathname: ROUTES.wizardInfo,
        }}
        state={nextState}
      />
    ) : (
      children
    )
  )
}

type AnonymousPageProps = {
  children: React.ReactElement
}

const AnonymousPage: React.FC<AnonymousPageProps> = ({ children }) => {
  const { data: user } = useUser()
  let location = useLocation()
  const { from, ...restState } = (location.state as any) ?? {}
  return (user && isAnonymousRoute(location.pathname)) ||
    (user &&
      from !== undefined &&
      !isAnonymousRoute(from) &&
      !from.startsWith(ONBOARDING_PREFIX)) ? (
    <Navigate replace to={from ?? ROUTES.home} state={restState} />
  ) : (
    children
  )
}

const LoadingUserState: React.FC = () => {
  return (
    <Center h="100vh">
      <CircularProgress color="blue.500" isIndeterminate />
    </Center>
  )
}

const App: React.FC = () => {
  const auth = getAuth()
  const { data: user } = useUser()
  const {
    data: profile,
    isFetched: isProfileFetched,
    isLoading: isLoadingProfile,
  } = useUserProfile()

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isFirstLoad && user && !isProfileFetched) {
      setIsFirstLoad(false)
    }
  }, [user, isFirstLoad, isProfileFetched])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      queryClient.resetQueries()
      // if there is a user, cancel loading when user is loaded
      if (user == null) {
        queryClient.removeQueries()
        setIsFirstLoad(false)
      }
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      user !== undefined &&
      profile !== undefined &&
      !location.pathname.startsWith(ONBOARDING_PREFIX) &&
      (profile.role === undefined ||
        profile?.basicDetails === undefined ||
        (profile.role === 'wizard' && profile.wizardDetails === undefined))
    ) {
      if (profile.role === undefined) {
        navigate(
          { pathname: ROUTES.roleSelect },
          { replace: true, state: location.state }
        )
      } else if (profile.basicDetails === undefined) {
        navigate(
          { pathname: ROUTES.basicInfo },
          { replace: true, state: location.state }
        )
      } else if (
        profile.role === 'wizard' &&
        profile.wizardDetails === undefined
      ) {
        navigate(
          { pathname: ROUTES.wizardInfo },
          { replace: true, state: location.state }
        )
      }
    }
    // eslint-disable-next-line
  }, [profile, navigate, user, location.pathname])

  const userProfileMutation = useCreateUserProfileMutation()
  // Create empty profile if none exists
  useEffect(() => {
    if (
      !profile &&
      !isLoadingProfile &&
      user &&
      !userProfileMutation.isLoading
    ) {
      userProfileMutation.mutate({ user: { id: user.uid } as User })
    }
  }, [profile, user, userProfileMutation, isLoadingProfile])

  // User is undefined until firebase is able to read
  if (
    isFirstLoad ||
    (!(user === null || user === undefined) && profile === undefined)
  ) {
    return <LoadingUserState />
  }

  return (
    <div className="App">
      <Routes>
        <Route
          path={ROUTES.login}
          element={
            <AnonymousPage>
              <Login />
            </AnonymousPage>
          }
        />
        <Route
          path={ROUTES.register}
          element={
            <AnonymousPage>
              <Register />
            </AnonymousPage>
          }
        />
        <Route
          path={ROUTES.passwordReset}
          element={
            <AnonymousPage>
              <PasswordReset />
            </AnonymousPage>
          }
        />
        <Route
          path={ROUTES.passwordUpdate}
          element={
            <AnonymousPage>
              <PasswordUpdate />
            </AnonymousPage>
          }
        />
        <Route
          path={ROUTES.roleSelect}
          element={
            <PrivatePage>
              <InitialUserRole />
            </PrivatePage>
          }
        />
        <Route
          path={ROUTES.basicInfo}
          element={
            <PrivatePage>
              <BasicUserInfo />
            </PrivatePage>
          }
        />
        <Route
          path={ROUTES.wizardInfo}
          element={
            <PrivatePage>
              <WizardInfo />
            </PrivatePage>
          }
        />
        <Route
          path={ROUTES.profile}
          element={
            <PrivatePage>
              <Profile />
            </PrivatePage>
          }
        />
        {/*
        <Route
          path={ROUTES.wizardDashboard}
          element={
            <PrivatePage>
              <WizardHome />
            </PrivatePage>
          }
        />
        */}
        <Route
          path={ROUTES.travelerDashboard}
          element={
            <AnonymousPage>
              <TravelerHome />
            </AnonymousPage>
          }
        />
        <Route
          path={ROUTES.wizardDetail}
          element={
            <AnonymousPage>
              <WizardDetail />
            </AnonymousPage>
          }
        />
        <Route
          path={ROUTES.bookings}
          element={
            <PrivatePage>
              <Bookings />
            </PrivatePage>
          }
        />
        <Route
          path={ROUTES.bookingDetail}
          element={
            <PrivatePage>
              <BookingDetail />
            </PrivatePage>
          }
        />
        <Route
          path={ROUTES.admin}
          element={
            <PrivatePage>
              <Admin />
            </PrivatePage>
          }
        />
        <Route
          path={ROUTES.home}
          element={
            profile?.role === 'wizard' ? (
              <Navigate replace to={ROUTES.bookings} state={location.state} />
            ) : (
              <Navigate
                replace
                to={ROUTES.travelerDashboard}
                state={location.state}
              />
            )
          }
        />
        <Route
          path="*"
          element={<Navigate replace to={ROUTES.home} state={location.state} />}
        />
      </Routes>
    </div>
  )
}
export default App
