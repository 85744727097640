import { Avatar } from '@chakra-ui/avatar'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Flex } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu'
import { useLogoutMutation, useUser, useUserProfile } from 'hooks/user'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { ROUTES } from 'ts/siteConstants'
import { useUserProfilePhoto } from 'hooks/user'
import { Button } from '@chakra-ui/react'

const UserMenu: React.FC = () => {
  const { data: user } = useUser()
  const { data: profile } = useUserProfile()
  const logoutMutation = useLogoutMutation()
  const { data: userProfilePhoto } = useUserProfilePhoto(user?.uid)
  const location = useLocation()
  return (
    <Flex as={Flex} direction="row" justify="flex-end">
      {user === null ? (
        <>
          <Button
            as={RouterLink}
            to={ROUTES.register}
            variant="solid"
            state={{ from: location.pathname }}
          >
            Sign up
          </Button>
          <Button
            as={RouterLink}
            to={ROUTES.login}
            variant="outline"
            ml={4}
            state={{ from: location.pathname }}
          >
            Login
          </Button>
        </>
      ) : (
        <Menu placement="bottom-end">
          <MenuButton
            as={Flex}
            direction="row"
            align="center"
            border="1px"
            borderColor="gray.200"
            h="42px"
            w="77px"
            py="5px"
            pl="12px"
            pr="5px"
            borderRadius="full"
            _hover={{ shadow: 'md' }}
          >
            <HamburgerIcon />
            <Avatar
              src={userProfilePhoto}
              boxSize="30px"
              ml="12px"
              borderWidth="2px"
              padding="2px"
            />
          </MenuButton>
          <MenuList shadow="base">
            <MenuItem
              as={RouterLink}
              to={ROUTES.travelerDashboard}
              display={{ base: 'flex', sm: 'none' }}
            >
              Find a Local expert
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to={ROUTES.bookings}
              display={{ base: 'flex', sm: 'none' }}
            >
              Your trips
            </MenuItem>
            <MenuItem as={RouterLink} to={ROUTES.profile}>
              View profile
            </MenuItem>
            {profile?.isAdmin && (
              <MenuItem as={RouterLink} to={ROUTES.admin}>
                Admin
              </MenuItem>
            )}
            <MenuItem onClick={() => logoutMutation.mutate()}>Logout</MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}
export default UserMenu
