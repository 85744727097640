import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import { WizardReview } from '@traviqo/data-model'
import StarRating from 'components/StarRating/StarRating'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  stars: yup.number().positive('Required').required('Required'),
  publicReview: yup.string().required('Required'),
  howDidItGo: yup.string().required('Required'),
  whatDidYouLike: yup.string().required('Required'),
  whatCouldBeBetter: yup.string().required('Required'),
})

type Props = {
  wizardName: string
  bookingId: string
  onSubmit?: (data: WizardReview) => Promise<void>
}

// user review of a wizard
const WizardReviewForm: React.FC<Props> = ({
  wizardName,
  bookingId,
  onSubmit,
}) => {
  const formik = useFormik({
    initialValues: {
      stars: -1,
      publicReview: '',
      howDidItGo: '',
      whatDidYouLike: '',
      whatCouldBeBetter: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await onSubmit?.({ ...values, id: bookingId })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack direction="column" spacing="4">
        <Heading fontStyle="italic" size="xs">
          Public Review
        </Heading>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.stars && formik.touched.stars}
        >
          <FormLabel>
            How many stars would you give your experience with {wizardName}?
          </FormLabel>
          <StarRating
            value={formik.values.stars}
            onChange={(newValue) => formik.setFieldValue('stars', newValue)}
          />
          <FormErrorMessage>{formik.errors.stars}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.publicReview && formik.touched.publicReview
          }
        >
          <FormLabel>Public review text</FormLabel>
          <Textarea
            name="publicReview"
            onChange={formik.handleChange}
            value={formik.values.publicReview}
          />
          <FormErrorMessage>{formik.errors.publicReview}</FormErrorMessage>
        </FormControl>
        <Heading fontStyle="italic" size="xs">
          Private fields (internal use only, will not be shared publicly)
        </Heading>
        <FormControl
          isRequired
          isInvalid={!!formik.errors.howDidItGo && formik.touched.howDidItGo}
        >
          <FormLabel>
            How was your overall experience with {wizardName}?
          </FormLabel>
          <Textarea
            name="howDidItGo"
            onChange={formik.handleChange}
            value={formik.values.howDidItGo}
          />
          <FormErrorMessage>{formik.errors.howDidItGo}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.whatDidYouLike && formik.touched.whatDidYouLike
          }
        >
          <FormLabel>What did you like about the meeting?</FormLabel>
          <Textarea
            name="whatDidYouLike"
            onChange={formik.handleChange}
            value={formik.values.whatDidYouLike}
          />
          <FormErrorMessage>{formik.errors.whatDidYouLike}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            !!formik.errors.whatCouldBeBetter &&
            formik.touched.whatCouldBeBetter
          }
        >
          <FormLabel>What could have been done better?</FormLabel>
          <Textarea
            name="whatCouldBeBetter"
            onChange={formik.handleChange}
            value={formik.values.whatCouldBeBetter}
          />
          <FormErrorMessage>{formik.errors.whatCouldBeBetter}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          size="lg"
          isLoading={formik.isSubmitting}
          isDisabled={!formik.dirty}
        >
          Leave review
        </Button>
      </Stack>
    </form>
  )
}
export default WizardReviewForm
