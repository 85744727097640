import type * as firestoreClientLib from 'firebase/firestore'
import type * as firestoreAdminLib from 'firebase-admin/firestore'

import {
  Booking,
  User,
  AsyncJob,
  LocalLocations,
  LocalLocationsMeta,
  Location,
  Reviews,
} from '@traviqo/data-model'

// Base class for field serializers
interface FirestoreFieldSerializer<T, F> {
  toFirestore: (data: T) => F
  fromFirestore: (data: F, options?: firestoreClientLib.SnapshotOptions) => T
}

interface DateSerializerOptions {
  autoUpdate: boolean
}

// Converting a Firebase Timestamp to date when used
const DateSerializer = <
  T extends firestoreClientLib.Timestamp | firestoreAdminLib.Timestamp,
  F extends firestoreClientLib.FieldValue | firestoreAdminLib.FieldValue
>(
  serverTimestamp: () => F,
  fromDate: (d: Date) => T,
  options: DateSerializerOptions = {
    autoUpdate: false,
  }
): FirestoreFieldSerializer<Date, T> => ({
  toFirestore: (data) => {
    if (options.autoUpdate) {
      return serverTimestamp() as any
    }
    return data
      ? fromDate(data)
      : // If value is not defined, assume that we are creating and want a default server timestamp value
        (serverTimestamp() as any)
  },
  fromFirestore: (data, options) => {
    // If server didn't set a date, return an estimate of now until real value
    return options?.serverTimestamps === 'none' ? new Date() : data.toDate()
  },
})

type FieldTransforms<F extends Record<string, any>> = {
  [K in keyof Partial<F>]: FirestoreFieldSerializer<any, any>
}

type JoinedFirestoreDataConverter<T> =
  | firestoreClientLib.FirestoreDataConverter<T>
  | firestoreAdminLib.FirestoreDataConverter<T>

// Generic conversion for a collection in firebase to a typescript type with
// support for serializing specific fields
const clientConverter = <T extends { id?: string; [key: string]: any }>(
  fieldTransforms?: FieldTransforms<T>
): firestoreClientLib.FirestoreDataConverter<T> => ({
  toFirestore: (data: firestoreClientLib.WithFieldValue<T>) => {
    data.id && delete data.id
    fieldTransforms &&
      Object.keys(fieldTransforms).forEach(
        (key) =>
          ((data as Record<string, any>)[key] = fieldTransforms[
            key
          ].toFirestore(data[key]))
      )
    return data
  },
  fromFirestore: (
    snapshot: firestoreClientLib.QueryDocumentSnapshot,
    options?: firestoreClientLib.SnapshotOptions
  ) => {
    let snapshotData = snapshot.data()
    fieldTransforms &&
      Object.keys(fieldTransforms).forEach(
        (key) =>
          (snapshotData[key] = fieldTransforms[key].fromFirestore(
            snapshotData[key],
            options
          ))
      )
    return { id: snapshot.id, ...snapshotData } as T
  },
})

const adminConverter = <T extends { id?: string; [key: string]: any }>(
  fieldTransforms?: FieldTransforms<T>
): firestoreAdminLib.FirestoreDataConverter<T> => ({
  toFirestore: (data: T) => {
    data.id && delete data.id
    fieldTransforms &&
      Object.keys(fieldTransforms).forEach(
        (key) =>
          ((data as Record<string, any>)[key] = fieldTransforms[
            key
          ].toFirestore(data[key]))
      )
    return data
  },
  fromFirestore: (snapshot: firestoreAdminLib.QueryDocumentSnapshot) => {
    let snapshotData = snapshot.data()
    fieldTransforms &&
      Object.keys(fieldTransforms).forEach(
        (key) =>
          (snapshotData[key] = fieldTransforms[key].fromFirestore(
            snapshotData[key]
          ))
      )
    return { id: snapshot.id, ...snapshotData } as T
  },
})

const getClientCollections = (
  typedCollection: <U>(
    path: string,
    fieldTransforms?: FieldTransforms<U>
  ) => firestoreClientLib.CollectionReference<U>,
  serverTimestamp,
  fromDate
) => {
  return {
    userCollection: typedCollection<User>('users', {
      createTime: DateSerializer(serverTimestamp, fromDate),
      lastUpdate: DateSerializer(serverTimestamp, fromDate, {
        autoUpdate: true,
      }),
    }),
    userLocationsCollection: (userId: string) =>
      typedCollection<Location>(`users/${userId}/locations`),
    userReviews: (userId: string) =>
      typedCollection<Reviews>(`users/${userId}/reviews`),
    bookingCollection: typedCollection<Booking>('bookings', {
      createTime: DateSerializer(serverTimestamp, fromDate),
      lastUpdate: DateSerializer(serverTimestamp, fromDate, {
        autoUpdate: true,
      }),
    }),
    jobCollection: typedCollection<AsyncJob>('jobs', {
      createTime: DateSerializer(serverTimestamp, fromDate),
      executeTime: DateSerializer(serverTimestamp, fromDate),
    }),
    localLocationsCollection: typedCollection<LocalLocations>('localLocations'),
    localLocationsMetaCollection: typedCollection<LocalLocationsMeta>(
      'localLocationsMeta'
    ),
  }
}

const getAdminCollections = (
  typedCollection: <U>(
    path: string,
    fieldTransforms?: FieldTransforms<U>
  ) => firestoreAdminLib.CollectionReference<U>,
  serverTimestamp,
  fromDate
) => {
  return {
    userCollection: typedCollection<User>('users', {
      createTime: DateSerializer(serverTimestamp, fromDate),
      lastUpdate: DateSerializer(serverTimestamp, fromDate, {
        autoUpdate: true,
      }),
    }),
    userLocationsCollection: (userId: string) =>
      typedCollection<Location>(`users/${userId}/locations`),
    userReviews: (userId: string) =>
      typedCollection<Reviews>(`users/${userId}/reviews`),
    bookingCollection: typedCollection<Booking>('bookings', {
      createTime: DateSerializer(serverTimestamp, fromDate),
      lastUpdate: DateSerializer(serverTimestamp, fromDate, {
        autoUpdate: true,
      }),
    }),
    jobCollection: typedCollection<AsyncJob>('jobs', {
      createTime: DateSerializer(serverTimestamp, fromDate),
      executeTime: DateSerializer(serverTimestamp, fromDate),
    }),
    localLocationsCollection: typedCollection<LocalLocations>('localLocations'),
    localLocationsMetaCollection: typedCollection<LocalLocationsMeta>(
      'localLocationsMeta'
    ),
  }
}

export const getTypedFirestore = (
  firestore: firestoreClientLib.Firestore,
  clientLib: typeof firestoreClientLib
) => {
  // Define our collections in a database and apply a converter
  const typedCollection = <T extends { id?: string } & Record<string, any>>(
    collectionPath: string,
    fieldTransforms?: FieldTransforms<T>
  ) =>
    clientLib
      .collection(firestore as firestoreClientLib.Firestore, collectionPath)
      .withConverter<T>(clientConverter<T>(fieldTransforms))

  return getClientCollections(
    typedCollection,
    clientLib.serverTimestamp,
    clientLib.Timestamp.fromDate
  )
}

export const getTypedAdminFirestore = (
  firestore: firestoreAdminLib.Firestore,
  adminLib: typeof firestoreAdminLib
) => {
  // Define our collections in a database and apply a converter
  const typedCollection = <T extends { id?: string }>(
    collectionPath: string,
    fieldTransforms?: FieldTransforms<T>
  ) =>
    (firestore as firestoreAdminLib.Firestore)
      .collection(collectionPath)
      .withConverter(adminConverter<T>(fieldTransforms))
  return getAdminCollections(
    typedCollection,
    adminLib.FieldValue.serverTimestamp,
    adminLib.Timestamp.fromDate
  )
}
