import React from 'react'
import { useCallbackRef, useControllableProp } from '@chakra-ui/hooks'
import { Select } from '@chakra-ui/select'
import { UserRole } from '@traviqo/data-model'

export const roleOptions: { label: string; value: UserRole }[] = [
  {
    label: 'I want to travel',
    value: 'traveler',
  },
  {
    label: 'I want to plan trips for others',
    value: 'wizard',
  },
]

export interface Props {
  value?: string
  onChange?: (value: UserRole) => void
}

const RoleSelect: React.FC<Props> = (props) => {
  const { value: valueProp, onChange: onChangeProp } = props
  const onChangePropCallbackRef = useCallbackRef(onChangeProp)
  const [valueState, setValueState] = React.useState<string>()
  const [isControlled, value] = useControllableProp(valueProp, valueState)
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (!isControlled) {
        setValueState(e.target.value)
      }
      onChangePropCallbackRef?.(e.target.value as UserRole)
    },
    [onChangePropCallbackRef, isControlled]
  )
  return (
    <Select value={value} onChange={onChange}>
      {roleOptions.map((roleOption) => (
        <option key={roleOption.value} value={roleOption.value}>
          {roleOption.label}
        </option>
      ))}
    </Select>
  )
}
export default RoleSelect
