import { useMutation, useQuery } from 'react-query'
import {
  approveWizard,
  getUnseenWizards,
  denyWizard,
} from 'services/firebaseService'
import { queryClient } from 'ts/queryClient'

export const useUnseenWizards = () =>
  useQuery('unseen-wizards', async () => await getUnseenWizards())

export const useApproveWizardMutation = () =>
  useMutation<void, Error, { wizardId: string }>(
    async ({ wizardId }) => await approveWizard(wizardId),
    {
      onSuccess: () => queryClient.invalidateQueries('unseen-wizards'),
    }
  )

export const useDenyWizardMutation = () =>
  useMutation<void, Error, { wizardId: string }>(
    async ({ wizardId }) => await denyWizard(wizardId),
    {
      onSuccess: () => queryClient.invalidateQueries('unseen-wizards'),
    }
  )
