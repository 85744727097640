import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { Booking } from '@traviqo/data-model'
import { ROUTES } from 'ts/siteConstants'
import LocationIcon from 'assets/icons/location.svg'
import UserAvatar from 'components/UserAvatar/UserAvatar'

type Props = {
  booking: Booking
  isWizard: boolean
}

const BookingCard: React.FC<Props> = ({ booking, isWizard }) => {
  const otherUserName = isWizard
    ? `${(booking as Booking<false>).user.firstName} ${
        (booking as Booking<false>).user.lastName
      }`
    : `${(booking as Booking<true>).wizard.firstName} ${
        (booking as Booking<true>).wizard.lastName
      }`
  return (
    <Box
      w="full"
      p={{ base: 4, sm: 8 }}
      borderRadius="4xl"
      border="2px solid"
      borderColor="lightGray"
    >
      <Stack
        direction={{ base: 'column', sm: 'row' }}
        spacing={{ base: 2, sm: 0 }}
        w="full"
        align="center"
      >
        <UserAvatar userId={isWizard ? booking.userId : booking.wizardId} />
        <Stack
          direction="column"
          pl={{ base: 0, sm: 4 }}
          spacing={{ base: 2, sm: 1 }}
          w={{ base: 'full', sm: '3xs' }}
          flexGrow={1}
          align={{ base: 'center', sm: 'flex-start' }}
        >
          <Heading size="sm" color="green">
            {otherUserName}
          </Heading>
          {!isWizard && (
            <Flex direction="row" align="center">
              <Image src={LocationIcon} w="24px" pr={1} />
              <Heading size="xs">
                {isWizard
                  ? `${
                      (booking as Booking<false>).user.cityState ??
                      (booking as Booking<false>).user.city
                    }, ${(booking as Booking<false>).user.country}`
                  : `${
                      (booking as Booking<true>).wizard.cityState ??
                      (booking as Booking<true>).wizard.city
                    }, ${(booking as Booking<true>).wizard.country}`}
              </Heading>
            </Flex>
          )}
          <Text
            textOverflow="ellipsis"
            display="-webkit-box"
            overflow="hidden"
            style={{ WebkitLineClamp: 6, WebkitBoxOrient: 'vertical' }}
          >
            <b>About: </b>
            {isWizard
              ? (booking as Booking<false>).user.about
              : (booking as Booking<true>).wizard.about}
          </Text>
        </Stack>
        <Box height={44} display={{ base: 'none', sm: 'block' }}>
          <Divider orientation="vertical" height="full" mx={8} />
        </Box>
        <Stack direction="column" spacing={4} align="center" flexGrow={1}>
          <Text textStyle="h4">
            {new Date(booking.dateTime).toLocaleString([], {
              dateStyle: 'long',
              timeStyle: 'short',
            })}
          </Text>
          <Text>
            Chat about trip to <b>{booking.destination}</b>
          </Text>
          <Button
            as={RouterLink}
            to={generatePath(ROUTES.bookingDetail, { bookingId: booking.id })}
            w="full"
            colorScheme="green"
          >
            DETAILS
          </Button>
        </Stack>
      </Stack>
    </Box>
    /*
    <Button
      as={RouterLink}
      to={`${generatePath(ROUTES.bookingDetail, { bookingId: booking.id })}`}
      colorScheme="gray"
      variant="outline"
      shadow="md"
      w="full"
      p={8}
      key={booking.id}
      borderRadius="md"
    >
      <Flex direction="row" justify="space-between">
        <Text>
          Chat with{' '}
          <strong>
            {isWizard
              ? `${(booking as Booking<false>).user.firstName} ${
                  (booking as Booking<false>).user.lastName
                }`
              : `${(booking as Booking<true>).wizard.firstName} ${
                  (booking as Booking<true>).wizard.lastName
                }`}
          </strong>{' '}
          on <strong>{new Date(booking.dateTime).toLocaleString()}</strong>{' '}
          about <strong>{booking.destination}</strong>
        </Text>
      </Flex>
    </Button>
              */
  )
}
export default BookingCard
