import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './date-picker.css'

const RDP = ReactDatePicker as any
const DatePicker: React.FC<ReactDatePickerProps> = (props) => {
  return <RDP {...props} />
}

export default DatePicker
