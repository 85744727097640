//import Stripe from 'stripe'

export type WizardSearch = {
  location?: string
  wizardIds?: string[]
}

export const publicBasicUserDetailsFields = [
  'firstName',
  'lastName',
  'city',
  'state',
  'country',
  'cityState',
  'locationId',
  'geohash',
  'lat',
  'lng',
  'timezone',
  'about',
]

export interface PublicBasicUserDetails {
  firstName: string
  lastName: string
  // @deprecated
  city?: string
  // @deprecated
  state?: string
  country: string
  cityState?: string
  locationId?: string
  geohash?: string
  lat?: number
  lng?: number
  timezone: Timezone
  about: string
}

export interface PrivateBasicUserDetails {
  stripeCustomerId?: string
}

export type BasicUserDetails = PublicBasicUserDetails & PrivateBasicUserDetails

export type DaysOfWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
export const DAYS_OF_WEEK: DaysOfWeek[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const DAYS_OF_WEEK_TO_NUMBER: Record<DaysOfWeek, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

export type TimeRange = {
  start: string
  end: string
}

export const ALL_TIMES = Array.from(Array(24).keys()).flatMap((hour: number) =>
  Array.from(Array(4).keys()).map(
    (min) =>
      `${hour.toLocaleString(undefined, {
        minimumIntegerDigits: 2,
      })}:${(min * 15).toLocaleString(undefined, {
        minimumIntegerDigits: 2,
      })}`
  )
)

export type AvailableDaysOfWeek = Partial<Record<DaysOfWeek, TimeRange[]>>

export type Timezone = {
  abbrev: string
  altName: string
  label: string
  offset: number
  value: string
}

export const publicWizardUserDetailsFields = [
  'rate',
  'availableDaysOfWeek',
  'adviceRegions',
  'adviceRegionsList',
  'whereExpert',
  'offeredAddons',
]

export interface LocationInfo {
  label: string
  locationId: string
  lat?: number
  lng?: number
  geohash?: string
}

export type LocationAutoCompleteType = 'city' | 'region'

export interface PublicWizardUserDetails {
  rate: number
  availableDaysOfWeek: AvailableDaysOfWeek
  // @depricated
  adviceRegions?: string
  adviceRegionsList?: LocationInfo[]
  whereExpert?: string
  offeredAddons?: BookingAddonType[]
}

export interface PrivateWizardUserDetails {
  internalApplicationLetter: string
  stripeAccount?: string
  active: boolean
  profileReviewed: boolean
  locationListDocument?: string
  locationListIds?: string[]
}

export type WizardUserDetails = PublicWizardUserDetails &
  PrivateWizardUserDetails

export type UserRole = 'traveler' | 'wizard'

export type EmailSubscriptions = {
  newsletter?: boolean
  productUpdates?: boolean
}

export interface User {
  id: string
  isAdmin?: boolean
  createTime: Date
  lastUpdate: Date

  role?: UserRole
  basicDetails?: BasicUserDetails
  wizardDetails?: WizardUserDetails
  emailsSent?: {
    welcomeEmail?: boolean
  }
  emailSubscriptions?: EmailSubscriptions
}

export type WizardPublicProfile<WithImages = true> = PublicBasicUserDetails &
  PublicWizardUserDetails & {
    id: string
    images: WithImages extends true ? string[] : undefined
  }

export interface StripeCard {
  id: string
  type: 'card'
  brand: string
  expireMonth: number
  expireYear: number
  lastFourDigits: string
}

export interface StripeCustomer {
  paymentsEnabled: boolean
  payments: StripeCard[]
}

export interface StripeCustomerSetupIntent {
  client_secret: string
}

export interface StripeAccount {
  payoutsEnabled: boolean
}

export const ADDON_FLIGHT_TICKETS = 'flightTickets'
export const ADDON_TRANSPORTATION = 'transportation'
export const ADDON_ACCOMMODATION = 'accommodation'
export const ADDON_ACTIVITIES = 'activities'

export type BookingAddonType =
  | typeof ADDON_FLIGHT_TICKETS
  | typeof ADDON_TRANSPORTATION
  | typeof ADDON_ACCOMMODATION
  | typeof ADDON_ACTIVITIES

export const BOOKING_ADDONS: BookingAddonType[] = [
  ADDON_FLIGHT_TICKETS,
  ADDON_TRANSPORTATION,
  ADDON_ACCOMMODATION,
  ADDON_ACTIVITIES,
]

export const BOOKING_ADDON_DISPLAY: Record<
  BookingAddonType,
  { name: string; description: string }
> = {
  [ADDON_FLIGHT_TICKETS]: {
    name: 'Flight search',
    description:
      'Helping users find flights on specified days between specified cities',
  },
  [ADDON_TRANSPORTATION]: {
    name: 'Local transportation',
    description:
      'Detailing local transportation options and the best way to get around town',
  },
  [ADDON_ACCOMMODATION]: {
    name: 'Hotels and accommodation',
    description: 'Providing personalized hotel or other accommodation options',
  },
  [ADDON_ACTIVITIES]: {
    name: 'Activities',
    description:
      "Providing personalized must see attractions and activities based on user's interest",
  },
}

export const BOOKING_ADDON_DURATION_MINUTES: Record<
  BookingAddonType,
  number
> = {
  flightTickets: 30,
  transportation: 15,
  accommodation: 30,
  activities: 15,
}

export const DEFAULT_TIME_RANGE: TimeRange = {
  start: '08:00',
  end: '17:00',
}

export const getPublicBasicUserDetails = (
  user: BasicUserDetails
): PublicBasicUserDetails =>
  publicBasicUserDetailsFields.reduce(
    (previous: any, current: any) => ({
      ...previous,
      [current]: user[current as keyof BasicUserDetails],
    }),
    {}
  ) as PublicBasicUserDetails

export const getPublicWizardUserDetails = (
  user: WizardUserDetails
): PublicWizardUserDetails =>
  publicWizardUserDetailsFields.reduce(
    (previous: any, current: any) => ({
      ...previous,
      [current]: user[current as keyof WizardUserDetails],
    }),
    {}
  ) as PublicWizardUserDetails
