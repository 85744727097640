import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Text,
  useColorModeValue,
  forwardRef,
} from '@chakra-ui/react'

export const DividerWithText = forwardRef<FlexProps, 'div'>((props, ref) => {
  const { children, ...flexProps } = props
  return (
    <Flex align="center" color="gray.300" ref={ref} {...(flexProps as any)}>
      <Box flex="1">
        <Divider borderColor="currentcolor" />
      </Box>
      <Text
        as="span"
        px="3"
        color={useColorModeValue('gray.600', 'gray.400')}
        fontWeight="medium"
      >
        {children}
      </Text>
      <Box flex="1">
        <Divider borderColor="currentcolor" />
      </Box>
    </Flex>
  )
})
