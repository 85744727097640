import { Button, Center, Heading, Image, Stack } from '@chakra-ui/react'
import { DividerWithText } from 'components/DividerWithText/DividerWithText'
import { useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

import RegisterForm from 'forms/RegisterForm/RegisterForm'
import { ROUTES } from 'ts/siteConstants'
import { useRegisterMutation } from 'hooks/user'
import logo64 from 'assets/icons/logo64.png'

const Register: React.FC = () => {
  const location = useLocation()
  const registerMutation = useRegisterMutation()
  return (
    <Center minH="100vh">
      <Stack direction="column" maxW="sm" w="full" m={4} p={4} spacing={4}>
        <Image
          src={logo64}
          alt="Traviqo logo"
          w="fit-content"
          alignSelf="center"
        />
        <Heading size="md" textAlign="center">
          Create new account
        </Heading>
        <RegisterForm
          onSubmit={(values) => registerMutation.mutate(values)}
          errors={
            registerMutation.isError ? registerMutation.error.fields : undefined
          }
        />
        <DividerWithText>or</DividerWithText>
        <Button
          as={RouterLink}
          to={{
            pathname: ROUTES.login,
          }}
          state={location.state}
          size="lg"
          w="full"
        >
          Already have an account?
        </Button>
      </Stack>
    </Center>
  )
}
export default Register
