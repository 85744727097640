import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30, // 30 min
      cacheTime: 1000 * 60 * 60, // 60 min
    },
  },
})
