import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { AcceptBooking } from '@traviqo/data-model'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  meetingUrl: yup.string().required('Required').url('Please enter a valid URL'),
})

type Props = {
  bookingId: string
  onSubmit?: (data: AcceptBooking) => Promise<void>
}

const AcceptBookingForm: React.FC<Props> = ({ bookingId, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      meetingUrl: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await onSubmit?.({ ...values, id: bookingId })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Stack direction="column" spacing="4">
        <FormControl
          isRequired
          isInvalid={!!formik.errors.meetingUrl && formik.touched.meetingUrl}
        >
          <FormLabel>Enter a Meeting URL</FormLabel>
          <Input
            name="meetingUrl"
            autoComplete="address-level1"
            onChange={formik.handleChange}
            value={formik.values.meetingUrl}
          />
          <Text mt={2}>
            Let’s add a place to meet! You may create a room via{' '}
            <Link
              href="https://meet.google.com/"
              target="_blank"
              referrerPolicy="no-referrer"
              textDecor="underline"
            >
              Google Meet
            </Link>{' '}
            or any other tool you might prefer.
          </Text>
          <FormErrorMessage>{formik.errors.meetingUrl}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          size="lg"
          isLoading={formik.isSubmitting}
          isDisabled={!formik.dirty}
        >
          Save
        </Button>
      </Stack>
    </form>
  )
}
export default AcceptBookingForm
