import * as firestoreClient from 'firebase/firestore'
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  connectFirestoreEmulator,
  updateDoc,
} from 'firebase/firestore'
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions'
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
} from 'firebase/storage'
import { firebaseApp } from '@traviqo/firebase-client'
import { getTypedFirestore } from '@traviqo/firestore-client'
import {
  AcceptBooking,
  Booking,
  CancelBooking,
  DeclineBooking,
  LocationAutoCompleteType,
  LocationInfo,
  NewBooking,
  Reviews,
  REVIEW_COLLECTION_KEY,
  StripeAccount,
  StripeCustomer,
  StripeCustomerSetupIntent,
  User,
  UserReview,
  WizardPublicProfile,
  WizardReview,
  WizardSearch,
} from '@traviqo/data-model'
import { v4 as uuid } from 'uuid'
import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from '@firebase/auth'
import { DeepPartial } from '@chakra-ui/react'

const firestore = getFirestore(firebaseApp)
const db = getTypedFirestore(firestore, firestoreClient)
const functions = getFunctions(firebaseApp, 'europe-west3')

if (process.env.NODE_ENV === 'development') {
  console.log('Using local functions emulator.')
  connectFunctionsEmulator(functions, 'localhost', 5001)
  console.log('Using local firebase DB.')
  connectFirestoreEmulator(firestore, 'localhost', 8080)
}

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  const passwordResetCallable = httpsCallable(functions, 'passwordReset')
  await passwordResetCallable({ email })
}

export const updatePassword = async (code: string, password: string) => {
  const auth = getAuth()
  await confirmPasswordReset(auth, code, password)
}

export const verifyPasswordReset = async (code: string): Promise<boolean> => {
  const auth = getAuth()
  try {
    await verifyPasswordResetCode(auth, code)
    return true
  } catch (e) {
    return false
  }
}

export const getUserProfile = async (userId: string) => {
  const userDoc = await getDoc(doc(db.userCollection, userId))
  return userDoc.data()
}

export const setUserProfile = async (user: User) => {
  await setDoc(doc(db.userCollection, user.id), user)
}

export const updateUserProfile = async (
  user: DeepPartial<User> & { id: string }
) => {
  await updateDoc(doc(db.userCollection, user.id), {
    ...user,
    lastUpdate: firestoreClient.serverTimestamp(),
  })
}

export const getUserReviews = async (userId: string): Promise<Reviews> => {
  const reviewsDoc = await getDoc(
    doc(db.userReviews(userId), REVIEW_COLLECTION_KEY)
  )
  return reviewsDoc.data() as Reviews
}

export const deactivateWizardProfile = async (userId: string) => {
  const deactivateWizardProfileCallable = httpsCallable(
    functions,
    'deactivateWizardProfile'
  )
  await deactivateWizardProfileCallable({ userId })
}

export const uploadUserProfilePhoto = async (userId: string, file: File) => {
  const storage = getStorage()

  const storageRef = ref(storage, `profile/${userId}/profilePhoto`)
  await uploadBytes(storageRef, file)
}

export const uploadUserPhotos = async (uid: string, files: File[]) => {
  const storage = getStorage()

  for (let i = 0; i < files.length; ++i) {
    const storageRef = ref(
      storage,
      `profile/${uid}/${uuid()}.${files[i].name.split('.').pop()}`
    )
    await uploadBytes(storageRef, files[i])
  }
}

export const getUserPictures = async (uid: string) => {
  const storage = getStorage()
  const listRef = ref(storage, `profile/${uid}`)
  const listResult = await listAll(listRef)

  // map doesn't work for some reason
  const urls = []
  for (let i = 0; i < listResult.items.length; ++i) {
    const url = await getDownloadURL(listResult.items[i])
    urls.push({
      path: listResult.items[i].fullPath,
      url,
    })
  }
  return urls
}

export const getUserProfilePhoto = async (
  uid: string
): Promise<string | undefined> => {
  const storage = getStorage()
  const profilePhotoRef = ref(storage, `profile/${uid}/profilePhoto`)
  try {
    return await getDownloadURL(profilePhotoRef)
  } catch (e) {
    return undefined
  }
}

export const getWizards = async (
  search: WizardSearch
): Promise<WizardPublicProfile[]> => {
  const getWizardsCallable = httpsCallable(functions, 'getWizards')
  const response = await getWizardsCallable(search)
  return response.data as WizardPublicProfile[]
}

export const getWizard = async (id: string): Promise<WizardPublicProfile> => {
  const getWizardCallable = httpsCallable(functions, 'getWizard')
  const response = await getWizardCallable({ id })
  return response.data as WizardPublicProfile
}

export const getLocatioinsAutoComplete = async (
  prefix: string,
  countryCode?: string,
  type?: LocationAutoCompleteType
): Promise<LocationInfo[]> => {
  const getLocatioinsAutoCompleteCallable = httpsCallable(
    functions,
    'getLocatioinsAutoComplete'
  )
  const reponse = await getLocatioinsAutoCompleteCallable({
    prefix,
    countryCode,
    type,
  })
  return reponse.data as LocationInfo[]
}

/**
 * Bookings
 */

export const createBooking = async (
  newBooking: NewBooking
): Promise<string> => {
  const createBookingCallable = httpsCallable(functions, 'createBooking')
  const response = await createBookingCallable({ newBooking })
  return response.data as string
}

export const acceptBooking = async (
  acceptBooking: AcceptBooking
): Promise<void> => {
  const acceptBookingCallable = httpsCallable(functions, 'acceptBooking')
  await acceptBookingCallable({ acceptBooking })
}

export const declineBooking = async (
  declineBooking: DeclineBooking
): Promise<void> => {
  const declineBookingCallable = httpsCallable(functions, 'declineBooking')
  await declineBookingCallable({ declineBooking })
}

export const cancelBooking = async (
  cancelBooking: CancelBooking
): Promise<void> => {
  const cancelBookingCallable = httpsCallable(functions, 'cancelBooking')
  await cancelBookingCallable({ cancelBooking })
}

export const getBookings = async (): Promise<Booking[]> => {
  const getBookingsCallable = httpsCallable(functions, 'getBookings')
  const response = await getBookingsCallable()
  return response.data as Booking[]
}

export const getBooking = async (id: string): Promise<Booking> => {
  const getBookingCallable = httpsCallable(functions, 'getBooking')
  const response = await getBookingCallable({ id })
  return response.data as Booking
}

export const getWizardBookings = async (): Promise<Booking[]> => {
  const getBookingsCallable = httpsCallable(functions, 'getWizardBookings')
  const response = await getBookingsCallable()
  return response.data as Booking[]
}

export const saveUserReview = async (userReview: UserReview): Promise<void> => {
  const saveUserReview = httpsCallable(functions, 'saveUserReview')
  await saveUserReview(userReview)
}

export const saveWizardReview = async (
  wizardReview: WizardReview
): Promise<void> => {
  const saveWizardReview = httpsCallable(functions, 'saveWizardReview')
  await saveWizardReview(wizardReview)
}

/**
 * Stripe
 */

export interface StripeOnboardLink {
  created: number
  expires_at: number
  object: string
  url: string
}

export const getStripeCustomer = async (): Promise<StripeCustomer> => {
  const getStripeCustomer = httpsCallable(functions, 'getStripeCustomer')
  const response = await getStripeCustomer()
  return response.data as StripeCustomer
}

export const removeStripePaymentMethod = async (
  id: string
): Promise<string> => {
  const removeStripePaymentMethod = httpsCallable(
    functions,
    'removeStripePaymentMethod'
  )
  const response = await removeStripePaymentMethod({ id })
  return response.data as string
}

export const getStripeCustomerSetupIntent = async (): Promise<StripeCustomerSetupIntent> => {
  const getStripeCustomerSetupIntent = httpsCallable(
    functions,
    'getStripeCustomerSetupIntent'
  )
  const response = await getStripeCustomerSetupIntent()
  return response.data as StripeCustomerSetupIntent
}

export const getStripeAccount = async (): Promise<StripeAccount> => {
  const getStripeAccount = httpsCallable(functions, 'getStripeAccount')
  const response = await getStripeAccount()
  return response.data as StripeAccount
}

export const getStripeOnboardWizardLink = async (): Promise<StripeOnboardLink> => {
  const getStripeOnboardWizardLinkCallable = httpsCallable(
    functions,
    'getStripeOnboardWizardLink'
  )
  const response = await getStripeOnboardWizardLinkCallable()
  return response.data as StripeOnboardLink
}

// Admin
export const getUnseenWizards = async (): Promise<
  (User & { email: string })[]
> => {
  const getUnseenWizardsCallable = httpsCallable(functions, 'getUnseenWizards')
  const response = await getUnseenWizardsCallable()
  return response.data as (User & { email: string })[]
}

export const approveWizard = async (wizardId: string): Promise<void> => {
  const approveWizardCallable = httpsCallable(functions, 'approveWizard')
  await approveWizardCallable({ wizardId })
}

export const denyWizard = async (wizardId: string): Promise<void> => {
  const denyWizardCallable = httpsCallable(functions, 'denyWizard')
  await denyWizardCallable({ wizardId })
}

// Local locations

export const getLocalLocations = async () => {
  const localLocationsDoc = await getDoc(doc(db.localLocationsCollection, '0'))
  return localLocationsDoc.data()
}
