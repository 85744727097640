import { Link as RouterLink } from 'react-router-dom'
import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import BasicUserInfoForm from 'forms/BasicUserInfoForm/BasicUserInfoForm'
import WizardInfoForm from 'forms/WizardInfoForm/WizardInfoForm'
import {
  useDeactivateWizardProfileMutation,
  useStripeAccount,
  useStripeOnboard,
  useUserImageMutation,
  useUserProfile,
  useUserProfileMutation,
} from 'hooks/user'
import { ROUTES } from 'ts/siteConstants'
import { Divider, Stack, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'
import { WizardUserDetails } from '@traviqo/data-model'
import { useToast } from '@chakra-ui/toast'
import React from 'react'
import { CircularProgress } from '@chakra-ui/progress'
import Payments from 'components/Payments/Payments'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Heading,
  useDisclosure,
} from '@chakra-ui/react'
import EmailSettings from 'components/EmailSettings/EmailSettings'

const BASIC_FORM = 'basic'
const WIZARD_FORM = 'wizard'

const Profile: React.FC = () => {
  const { data: profile } = useUserProfile()
  const userProfileMutation = useUserProfileMutation()
  const userImageMutation = useUserImageMutation()
  const [formSubmitted, setFormSubmitted] = React.useState<string>()
  const toast = useToast({
    position: 'bottom',
    status: 'success',
    duration: 4000,
    isClosable: true,
  })

  const {
    data: stripeAccount,
    isLoading: isLoadingStripeAccount,
  } = useStripeAccount(!!profile?.wizardDetails)
  const payoutsEnabled = stripeAccount && stripeAccount.payoutsEnabled

  const {
    data: stripeOnboard,
    isLoading: isLoadingStripeOnboard,
  } = useStripeOnboard(!!profile?.wizardDetails && payoutsEnabled)

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const deactivateWizardProfileMutation = useDeactivateWizardProfileMutation()

  return (
    <DefaultLayout>
      <Stack direction="column" divider={<Divider />} spacing="4" my="4">
        <BasicUserInfoForm
          initialValues={profile?.basicDetails}
          isLoading={
            userProfileMutation.isLoading && formSubmitted === BASIC_FORM
          }
          onSubmit={(basicDetails, files) => {
            setFormSubmitted(BASIC_FORM)
            userProfileMutation.mutate(
              {
                user: {
                  id: profile!.id,
                  basicDetails: {
                    ...profile?.basicDetails,
                    ...basicDetails,
                  },
                },
                files,
              },
              {
                onSuccess: () => {
                  toast({ title: 'Profile saved' })
                },
                onError: () => {
                  toast({ title: 'Please try again', status: 'error' })
                },
              }
            )
          }}
          isSubmittingImages={userImageMutation.isLoading}
          onSubmitImages={async (files) => {
            await userImageMutation.mutateAsync({
              uid: profile!.id,
              files,
            })
            toast({ title: 'Images saved' })
          }}
        />
        <EmailSettings />
        <Payments />
        {profile?.wizardDetails && (
          <Stack spacing={4}>
            <Heading size="sm">Profile Status</Heading>
            {!profile.wizardDetails.profileReviewed ? (
              <Text>We are reviewing your profile.</Text>
            ) : profile.wizardDetails.active ? (
              <Text>Your profile is active to users!</Text>
            ) : (
              <Text>Your profile is not currently active.</Text>
            )}
            {profile.wizardDetails.active && (
              <Button
                colorScheme="red"
                variant="ghost"
                w="fit-content"
                onClick={onConfirmDeleteOpen}
              >
                Disable profile
              </Button>
            )}
            <AlertDialog
              isOpen={isConfirmDeleteOpen}
              leastDestructiveRef={cancelRef}
              onClose={onConfirmDeleteClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Disable Profile
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards and you
                    will need to email info@traviqo.com to reactivate your
                    profile.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button
                      ref={cancelRef}
                      onClick={onConfirmDeleteClose}
                      variant="outline"
                      disabled={deactivateWizardProfileMutation.isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={async () => {
                        //todo call mutation
                        await deactivateWizardProfileMutation.mutateAsync({
                          userId: profile.id,
                        })
                        onConfirmDeleteClose()
                      }}
                      ml={4}
                      disabled={deactivateWizardProfileMutation.isLoading}
                    >
                      Disable
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Stack>
        )}
        {profile?.wizardDetails ? (
          <Stack spacing={4}>
            <Heading size="sm">Local Profile</Heading>
            <WizardInfoForm
              initialValues={profile?.wizardDetails}
              isLoading={
                userProfileMutation.isLoading && formSubmitted === WIZARD_FORM
              }
              onSubmit={(wizardDetails) => {
                setFormSubmitted(WIZARD_FORM)
                userProfileMutation.mutate(
                  {
                    user: {
                      id: profile!.id,
                      wizardDetails: {
                        ...profile.wizardDetails,
                        ...wizardDetails,
                      } as WizardUserDetails,
                    },
                  },
                  {
                    onSuccess: () => {
                      toast({ title: 'Profile saved' })
                    },
                    onError: () => {
                      toast({ title: 'Please try again', status: 'error' })
                    },
                  }
                )
              }}
            />
          </Stack>
        ) : (
          <Button as={RouterLink} to={ROUTES.wizardInfo}>
            Setup Local Expert Profile
          </Button>
        )}
        {profile?.wizardDetails && (
          <>
            {isLoadingStripeAccount && (
              <CircularProgress color="blue.500" isIndeterminate />
            )}
            {payoutsEnabled ? (
              <Text>Stripe payouts enabled!</Text>
            ) : (
              <Button
                as="a"
                href={stripeOnboard?.url ?? ''}
                isLoading={isLoadingStripeOnboard}
                w="fit-content"
                mt={4}
              >
                Setup Payout Information
              </Button>
            )}
          </>
        )}
      </Stack>
    </DefaultLayout>
  )
}
export default Profile
