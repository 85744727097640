import {
  BookingAddonType,
  BOOKING_ADDON_DURATION_MINUTES,
  PublicBasicUserDetails,
  WizardPublicProfile,
} from './User'

export type BookingAddon = {
  type: BookingAddonType
  additionalInfo: string
}

export interface Booking<IsUserView extends Boolean = Boolean> {
  id: string
  createTime: Date
  lastUpdate: Date
  userId: string
  fee: number //wizard hourly rate
  totalFee?: number

  declinedTime?: Date
  acceptedTime?: Date
  meetingUrl?: string
  canceledTime?: Date
  cancelUserId?: string

  completed: boolean
  wizardId: string
  dateTime: string
  duration: number
  destination: string
  whenIsTrip: string
  travelerCount: string
  whatHelpNeeded: string
  bookingAddons?: BookingAddon[]

  user: IsUserView extends false ? PublicBasicUserDetails : never
  wizard: IsUserView extends true ? WizardPublicProfile<false> : never

  userReviewOfWizard?: WizardReview
  wizardReviewOfUser?: UserReview
  isReviewedByUser?: boolean
  hasPaymentBeenCharged?: boolean
  emails?: {
    wizardRequestedEmailSent?: boolean
    travelerReminderEmailSent?: boolean
    startingEmailsSent?: boolean
    bookingReviewRemindersSent?: boolean
  }
}

export type NewBooking = Omit<
  Booking,
  | 'id'
  | 'createTime'
  | 'lastUpdate'
  | 'userId'
  | 'completed'
  | 'user'
  | 'wizard'
  | 'fee'
>

export type AcceptBooking = {
  id: string
  meetingUrl: string
}

export type DeclineBooking = {
  id: string
}

export type CancelBooking = {
  id: string
}

// wizard review of a user
export type UserReview = {
  id: string
  howDidItGo: string
  chargeCustomer?: boolean
}

// user review of a wizard
export type WizardReview = {
  id: string
  stars: number
  publicReview: string
  howDidItGo: string
  whatDidYouLike: string
  whatCouldBeBetter: string
}

export const calculateBookingCost = (
  fee: number,
  duration: number,
  bookingAddons?: BookingAddon[]
) =>
  fee *
  ((duration +
    (bookingAddons ?? [])
      .map((addon) => BOOKING_ADDON_DURATION_MINUTES[addon.type])
      .reduce((previous, current) => previous + current, 0)) /
    60)
