import { Flex } from '@chakra-ui/layout'
import { CloseButton, Stack, Text } from '@chakra-ui/react'
import { BookingAddon, BookingAddonType } from '@traviqo/data-model'

export const ADDON_DATA: Record<
  BookingAddonType,
  { label: string; helpText: string }
> = {
  flightTickets: {
    label: 'Flight search',
    helpText:
      'Please enter the starting city, ending city, dates, and any other additional information',
  },
  transportation: {
    label: 'Local transportation',
    helpText:
      'Please enter where you will be staying and any additional information',
  },
  accommodation: {
    label: 'Accomodation and lodging',
    helpText:
      'Please let us know what your preferred accommodation is (e.g. hotel, Airbnb, camping), how many people are joining, and any other relevant information',
  },
  activities: {
    label: 'Activities',
    helpText:
      'Please let us know what kind of activities you would like to do on your trip',
  },
}
type Props = {
  addon: BookingAddon
  onRemove?: () => void
}

const BookingAddonItem: React.FC<Props> = ({ addon, onRemove }) => {
  return (
    <Flex direction="row" justify="space-between">
      <Stack spacing={1}>
        <Text fontWeight="bold">{ADDON_DATA[addon.type].label}</Text>
        <Text
          textOverflow="ellipsis"
          display="-webkit-box"
          overflow="hidden"
          style={{ WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
        >
          {addon.additionalInfo}
        </Text>
      </Stack>
      {onRemove && <CloseButton onClick={() => onRemove()} />}
    </Flex>
  )
}
export default BookingAddonItem
