import React from 'react'
import { Avatar, Box, Skeleton } from '@chakra-ui/react'
import { useSetUserProfilePhotoMutation, useUserProfilePhoto } from 'hooks/user'

interface ProfilePictureUploadProps {
  userId?: string
  onError?: (error: string) => void
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  userId,
  onError,
}) => {
  const setUserProfilePhotoMutation = useSetUserProfilePhotoMutation()
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const {
    data: userProfilePhoto,
    isLoading: isLoadingUserProfilePhoto,
  } = useUserProfilePhoto(userId)

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target || !e.target.files || e.target.files.length < 1) {
      return
    }
    let file = Array.from(e.target.files)[0]
    if (file.size > 1000000) {
      onError?.('File must not be larger than 5MB.')
    } else if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg' &&
      file.type !== 'image/png'
    ) {
      onError?.('Image file must be a jpg, jpeg oder png file.')
    }
    //setInternalFile(file)
    userId &&
      (await setUserProfilePhotoMutation.mutateAsync({
        userId,
        file,
      }))
  }

  return isLoadingUserProfilePhoto ? (
    <Skeleton w="fit-content" borderRadius="full">
      <Avatar size="2xl" />
    </Skeleton>
  ) : (
    <Box>
      <input type="file" ref={fileInputRef} onChange={onFileChange} hidden />
      <Avatar
        src={userProfilePhoto}
        onClick={() => fileInputRef.current!.click()}
        size="2xl"
        cursor="pointer"
      />
    </Box>
  )
}
export default ProfilePictureUpload
