import { Button, Center, Heading } from '@chakra-ui/react'
import DefaultLayout from 'components/DefaultLayout/DefaultLayout'
import { ROUTES } from 'ts/siteConstants'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  message?: string
  returnToMessage?: string
  returnToRoute?: string
}

const NotFound: React.FC<Props> = ({
  message,
  returnToMessage,
  returnToRoute,
}) => {
  return (
    <DefaultLayout>
      <Center flexDirection="column" gridGap={4}>
        <Heading size="md">{message ?? 'Local not found'}</Heading>
        <Button as={RouterLink} to={returnToRoute ?? ROUTES.home}>
          {returnToMessage ?? 'To Homepage'}
        </Button>
      </Center>
    </DefaultLayout>
  )
}
export default NotFound
