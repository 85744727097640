import {
  CircularProgress,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import StarRating from 'components/StarRating/StarRating'
import { useWizardReviews } from 'hooks/user'

interface Props {
  wizardId: string
}

const ReviewsCard: React.FC<Props> = ({ wizardId }) => {
  const { data: wizardReviews, isLoading } = useWizardReviews(wizardId)
  return (
    <Stack
      w="full"
      p={{ base: 4, sm: 8 }}
      borderRadius="4xl"
      border="2px solid"
      borderColor="lightGray"
      direction="column"
      spacing={4}
    >
      <Heading size="sm">Reviews</Heading>
      {isLoading && <CircularProgress color="blue.500" isIndeterminate />}
      {!wizardReviews?.reviews && <Text>No reviews yet</Text>}
      {wizardReviews?.reviews && (
        <Stack direction="column" spacing={4} divider={<Divider />}>
          {wizardReviews.reviews.map((review) => (
            <Stack key={review.bookingId} direction="column" spacing={4}>
              <StarRating value={review.stars} />
              <Text>{review.reviewerName} said:</Text>
              <Text fontStyle="italic" px={4}>
                "{review.reviewText}"
              </Text>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  )
}
export default ReviewsCard
