export const ONBOARDING_PREFIX = '/onboarding'

export const ROUTES = {
  home: '/',
  login: '/login/',
  register: '/register/',
  passwordReset: '/password-reset',
  passwordUpdate: '/password-update',
  roleSelect: ONBOARDING_PREFIX + '/role-select/',
  basicInfo: ONBOARDING_PREFIX + '/basic-info/',
  wizardInfo: ONBOARDING_PREFIX + '/wizard-info/',
  travelerDashboard: '/traveler-dashboard/',
  wizardDashboard: '/wizard-dashboard/',
  wizardDetail: '/wizards/:wizardId',
  profile: '/profile/',
  setupPayments: '/setup-payments/',
  bookings: '/bookings',
  bookingDetail: '/bookings/:bookingId',
  admin: '/admin',
}
