import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Switch,
  useToast,
} from '@chakra-ui/react'
import { EmailSubscriptions } from '@traviqo/data-model'
import { useUserProfile, useUserProfileMutation } from 'hooks/user'

interface Props {}

const EmailSettings: React.FC<Props> = () => {
  const { data: profile, isLoading: isLoadingProfile } = useUserProfile()
  const userProfileMutation = useUserProfileMutation()
  const toast = useToast({
    position: 'bottom',
    status: 'success',
    duration: 4000,
    isClosable: true,
  })

  const settings = {
    newsletter: profile?.emailSubscriptions?.newsletter ?? true,
    productUpdates: profile?.emailSubscriptions?.productUpdates ?? true,
  }

  const setEmailPreference = (
    field: keyof EmailSubscriptions,
    value: boolean
  ) => {
    userProfileMutation.mutate(
      {
        user: {
          id: profile!.id,
          emailSubscriptions: {
            ...profile?.emailSubscriptions,
            [field]: value,
          },
        },
      },
      {
        onSuccess: () => {
          toast({ title: 'Preference Saved' })
        },
      }
    )
  }

  return (
    <Stack spacing="4">
      <Heading mb={4} size="sm">
        Email Settings
      </Heading>
      <FormControl as={Flex} direction="row">
        <FormLabel>Newsletter</FormLabel>
        <Switch
          onChange={(e) => setEmailPreference('newsletter', e.target.checked)}
          isChecked={settings.newsletter}
          disabled={isLoadingProfile || userProfileMutation.isLoading}
        />
      </FormControl>
      <FormControl as={Flex} direction="row">
        <FormLabel>Product Updates</FormLabel>
        <Switch
          onChange={(e) =>
            setEmailPreference('productUpdates', e.target.checked)
          }
          isChecked={settings.productUpdates}
          disabled={isLoadingProfile || userProfileMutation.isLoading}
        />
      </FormControl>
    </Stack>
  )
}
export default EmailSettings
