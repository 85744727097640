import React, { useState } from 'react'
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { usePasswordResetMutation } from 'hooks/user'
import logo64 from 'assets/icons/logo64.png'
import { ROUTES } from 'ts/siteConstants'
import { DividerWithText } from 'components/DividerWithText/DividerWithText'

const validationSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email'),
})

const PasswordReset: React.FC = () => {
  const location = useLocation()
  const [emailSent, setEmailSent] = useState(false)
  const passwordResetMutation = usePasswordResetMutation()
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      await passwordResetMutation.mutateAsync({ email: values.email })
      actions.setSubmitting(false)
      setEmailSent(true)
    },
  })

  return (
    <Center minH="100vh">
      <Stack direction="column" maxW="sm" w="full" m={4} p={4} spacing={4}>
        <Image
          src={logo64}
          alt="Traviqo logo"
          w="fit-content"
          alignSelf="center"
        />
        <Heading size="md" textAlign="center">
          Password Reset
        </Heading>
        {emailSent ? (
          <Stack spacing={5}>
            <Text textAlign="center">
              An email has been sent if the email exists in our system. Please
              follow the link in the email to reset your password.
            </Text>
            <Button
              as={RouterLink}
              to={{
                pathname: ROUTES.login,
              }}
              state={location.state}
            >
              Back to Login
            </Button>
          </Stack>
        ) : (
          <>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Stack spacing={4}>
                <FormControl
                  isRequired
                  isInvalid={
                    formik.touched.email && Boolean(formik.errors.email)
                  }
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <Button type="submit" isLoading={formik.isSubmitting}>
                  Reset Password
                </Button>
              </Stack>
            </form>
            <DividerWithText>or</DividerWithText>
            <Button
              as={RouterLink}
              to={{ pathname: ROUTES.login }}
              state={location.state}
              size="md"
              w="full"
            >
              Back to login
            </Button>
          </>
        )}
      </Stack>
    </Center>
  )
}
export default PasswordReset
