import React from 'react'
import { Center, Heading, Stack } from '@chakra-ui/react'
import BasicUserInfoForm from 'forms/BasicUserInfoForm/BasicUserInfoForm'
import { useUserProfile, useUserProfileMutation } from 'hooks/user'
import { useNavigate } from 'react-router'
import { ROUTES } from 'ts/siteConstants'

const BasicUserInfo: React.FC = () => {
  const navigate = useNavigate()
  const { data: profile } = useUserProfile()
  const userProfileMutation = useUserProfileMutation()
  return (
    <Center minH="100vh" overflowY="scroll" m="4">
      <Stack spacing="4" direction="column" maxW="xl" w="full">
        <Heading size="md" alignSelf="center">
          Tell us about yourself
        </Heading>
        <BasicUserInfoForm
          onSubmit={(basicDetails) =>
            userProfileMutation.mutate(
              {
                user: {
                  id: profile!.id,
                  basicDetails,
                },
              },
              {
                onSuccess: (_, data) => {
                  navigate(ROUTES.home)
                },
              }
            )
          }
        />
      </Stack>
    </Center>
  )
}
export default BasicUserInfo
