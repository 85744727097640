import { Image } from '@chakra-ui/react'
import { Box, Container, Flex, Text, SimpleGrid } from '@chakra-ui/layout'
import UserMenu from 'components/UserMenu/UserMenu'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { ROUTES } from 'ts/siteConstants'
import logo32 from 'assets/icons/logo32.png'
import { useUser } from 'hooks/user'

type Props = {
  children: React.ReactNode
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
  const { data: user } = useUser()
  const location = useLocation()
  return (
    <Container minH="100vh" maxW="7xl" pb="16" pt={4}>
      <SimpleGrid py="4" columns={{ base: 2, sm: 3 }} alignItems="center">
        <RouterLink to={ROUTES.home}>
          <Image src={logo32} alt="Traviqo logo" w="fit-content" />
        </RouterLink>
        <Flex justify="center" display={{ base: 'none', sm: 'flex' }}>
          <Box w="105px">
            <RouterLink to={ROUTES.travelerDashboard}>
              <Text
                fontWeight={
                  location.pathname === ROUTES.travelerDashboard
                    ? 'bold'
                    : undefined
                }
                textAlign="center"
              >
                Find a Local expert
              </Text>
            </RouterLink>
          </Box>
          {user !== null && (
            <>
              <Text mx={8}>|</Text>
              <Box w="105px">
                <RouterLink to={ROUTES.bookings}>
                  <Text
                    fontWeight={
                      location.pathname === ROUTES.bookings ? 'bold' : undefined
                    }
                    textAlign="center"
                  >
                    Your trips
                  </Text>
                </RouterLink>
              </Box>
            </>
          )}
        </Flex>
        <UserMenu />
      </SimpleGrid>
      <Container
        maxW={{ xl: 'container.xl', lg: 'container.lg' }}
        px={{ base: 0, sm: 4 }}
      >
        {children}
      </Container>
    </Container>
  )
}
export default DefaultLayout
